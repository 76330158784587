import {
    GET_GUIDE_SUCCESS,
    GET_LESSON_FORMAT_SUCCESS,
    GET_LESSON_TYPE_SUCCESS, GET_QUESTION_SUCCESS,
    GET_SUBJECT_SUCCESS, SET_GUIDE,
    SET_LESSON_FORMAT,
    SET_LESSON_TYPE,
    SET_QUESTION,
    SET_SUBJECT,
    START_FETCHING_TLV,
    STOP_FETCHING_TLV,
    SET_QUESTION_FORMAT
} from './action_type'

const initialState = {
    loading: false,
    data: null,
    subjects: null,
    lessonsType: null,
    lessonsFormat: null,
    questions: null,
    guides: null,
    videos: null,

    subject: null,
    lessonType: null,
    lessonFormat: null,
    question: null,
    guide: null,
    video: null,

    isCreateSuccess: false,
    isUpdateSuccess: false,
    isRemoveSuccess: false
}

export default function tlvReducer(state = initialState, action) {
    switch (action.type) {
        case START_FETCHING_TLV:
            return {...state, loading: true}
        case STOP_FETCHING_TLV:
            return {...state, loading: false}

        case GET_SUBJECT_SUCCESS:
            return {...state, subjects: action.payload}
        case GET_LESSON_TYPE_SUCCESS:
            return {...state, lessonsType: action.payload}
        case GET_LESSON_FORMAT_SUCCESS:
            return {...state, lessonsFormat: action.payload}
        case GET_QUESTION_SUCCESS:
            return {...state, questions: action.payload}
        case GET_GUIDE_SUCCESS:
            return {...state, guides: action.payload}

        case SET_SUBJECT:
            return {...state, subject: action.payload}
        case SET_LESSON_TYPE:
            return {...state, lessonType: action.payload}
        case SET_LESSON_FORMAT:
            return {...state, lessonFormat: action.payload}
        case SET_QUESTION:
            return {...state, question: action.payload}
        case SET_GUIDE:
            return {...state, guide: action.payload}
        case SET_QUESTION_FORMAT:
            return {...state, video: action.payload}
        default:
            return state
    }
}
