import store from 'store'
import { WIDTH_BUTTON } from '../constants'

export const setSizePage = (pageSize) => {
  store.set('pageSize', pageSize)
}

export const getSizePage = () => store.get('pageSize')

export const getWidthAction = (numberButton) => {
  return WIDTH_BUTTON * numberButton
}

export default {
  setSizePage,
  getSizePage,
  getWidthAction
}