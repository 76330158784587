import { NUMBER_STAR_DEFAULT } from '../constants'

//get rate by star
export const getRateStar = (totalStarReceiver, numberReiew) => {
  if (!totalStarReceiver || !numberReiew) return 0
  const totalStar = numberReiew * NUMBER_STAR_DEFAULT
  // number of star / 5
  const result = (totalStarReceiver / totalStar) * NUMBER_STAR_DEFAULT
  // decimal
  let n = Math.abs(result) // Change to positive
  let decimal = n - Math.floor(n)
  //check decimal
  if (decimal < 0.5 || decimal > 0.5) {
    return Math.round(result)
  }
  return result
}

export const getItemFromKey = (array, key) => {
  try {
    if (!array) return {}
    if (Array.isArray(array)) {
      return array.find(item => item.key == key)
    }
    return {}
  }catch (e) {
    return {}
  }
}

export default {
  getRateStar,
  getItemFromKey
}