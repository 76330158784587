export const GET_BOOK_SUCCESS = 'GET_BOOK_SUCCESS'

export const GET_LIST_CHAPTER_SUCCESS = 'GET_LIST_CHAPTER_SUCCESS'
export const SET_CHAPTER = 'SET_CHAPTER'

export const GET_LIST_LESSON_SUCCESS = 'GET_LIST_LESSON_SUCCESS'
export const SET_LESSON = 'SET_LESSON'

export const GET_LIST_THEORY_SUCCESS = 'GET_LIST_THEORY_SUCCESS'
export const GET_LIST_THEORY_SECTION_SUCCESS = 'GET_LIST_THEORY_SECTION_SUCCESS'

export const GET_LIST_VIDEO_SUCCESS = 'GET_LIST_VIDEO_SUCCESS'
export const GET_LIST_VIDEO_SECTION_SUCCESS = 'GET_LIST_VIDEO_SECTION_SUCCESS'

export const START_FETCHING_EDITOR_BOOK = 'START_FETCHING_EDITOR_BOOK'
export const STOP_FETCHING_EDITOR_BOOK = 'STOP_FETCHING_EDITOR_BOOK'

export const SET_THEORY = 'SET_THEORY'
export const SET_VIDEO = 'SET_VIDEO'

export const SET_THEORY_SECTION = 'SET_THEORY_SECTION'
export const SET_VIDEO_SECTION = 'SET_VIDEO_SECTION'