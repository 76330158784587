import { APPROVE } from '../constants/form'
import queryString from 'query-string'

export const getObjApprove = (isApprove, dataObj) => {
  return {...dataObj, admin_approve: !!isApprove ? APPROVE.allow : APPROVE.deny}
}

export const getValueApprove = (isApprove) => {
  return !!isApprove ? APPROVE.allow : APPROVE.deny
}

export const getNameProgram = (type) => {
  switch (type) {
    case 'CO_BAN':
      return 'Cơ bản'
    case 'NANG_CAO':
      return 'Nâng cao'
    case 'THI_QUOC_TE':
      return 'Thi quốc tế'
    case 'THI_OLYMPIC':
      return 'Thi Olympic'
    case 'KHAN_ACADEMY':
      return 'Khan Academy'
    default:
      return 'Không xác định'
  }
}

export const getQuery = (object, isApproved) => {
  if (isApproved) {
    object.admin_approve = 1
  }
  return queryString.stringify(object)
}

export const getApprove = (isApprove) => {
  return !!isApprove ? APPROVE.allow : APPROVE.deny
}

export default {
  getObjApprove,
  getValueApprove,
  getNameProgram,
  getQuery,
  getApprove
}