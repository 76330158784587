export {default as validate} from './validate'

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_PAGE_INDEX = 1
export const DEFAULT_QUANTITY = 0

export const DEFAUTL_WIDTH = 50
export const DEFAUTL_HEIGHT = 50

export const BASE_IMG_QUESTION_URL = `${process.env.REACT_APP_BASE_IMG_VNG}/public/questions/`

export const DEFAULT_FORMAT_DATE = 'DD-MM-YYYY'
export const FORMAT_YMD = 'YYYY-MM-DD'
export const SUPER_ADMIN = 'super_admin'

export const MAX_SIZE_BACKGROUND = 5 * 1024 * 1000
export const MAX_SIZE_AVATAR = 2 * 1024 * 1000

export const MAX_SIZE_MEDIA = 10 * 1024 * 1024 * 1024

export const TIME_DEBOUNCE = 500
export const MAX_LENGTH_STRING = 80

export const NUMBER_STAR_DEFAULT = 5
export const WIDTH_BUTTON = 57

export const MAX_LONG_NAME = 100
export const MAX_LONG_NAME_DB = 255

export const TYPE_IMAGE = {
  avatar: 'avatar',
  background: 'background'
}

export const TYPE_MEDIA = {
  audio: 'audio',
  video: 'video'
}

export const typeDisplayButton = {
  text: 'text',
  icon: 'icon',
  textAndIcon: 'textAndIcon'
}

export const NOTI_PERIODIC = 1;
export const NOTI_RESERVE = 2;
export const NEXTA_SYSTEM = 0;

export const NOTI_SYSTEM = 5;
export const NOTI_MESSAGE_NORMAL_TYPE = 1;
export const NOTI_MESSAGE_LEARN_REMINDER_TYPE = 2; 
export const NOTI_MESSAGE_SUGGEST_COURSE_TYPE = 3;