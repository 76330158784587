import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import question from './question/reducer'
import bookEditor from './book/reducer'
import tlv from './tlv/reducer'
import role from './role/reducers'
import fetching from './common/reducer'
import libManager from "./media_manger/reducer";
import chat from './chat/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    bookEditor,
    fetching,
    menu,
    question,
    role,
    settings,
    user,
    tlv,
    libManager,
    chat,
  })
