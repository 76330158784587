import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './scss/global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import * as serviceWorker from './serviceWorker'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { MathJaxContext } from 'better-react-mathjax';
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'

import 'moment/locale/vi'
import locale from 'antd/es/locale/vi_VN'

// middlewared
const history = createHashHistory()
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]

const store = createStore(
  reducers(history),
  composeEnhancer(applyMiddleware(...middlewares))
)
sagaMiddleware.run(sagas)

const configMathjax = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  }
}

ReactDOM.render(
  <Provider store={store}>
    <MathJaxContext config={configMathjax}>
      <ConfigProvider locale={locale}>
        <Router history={history} />
      </ConfigProvider>
    </MathJaxContext>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }