import _ from 'lodash'

const chooseRequired =  (choose) => `Vui lòng chọn ${choose}`
const typingRequired =  (name) => `Vui lòng nhập ${name}`

//message error
export const validateError = {
  chooseRequired: chooseRequired,
  typingRequired: typingRequired,
  required: 'Trường bắt buộc nhập',

  target_quantity: typingRequired('số lượng yêu cầu'),
  target_percent: typingRequired('mục tiêu'),
  type_rule_grant_coin: typingRequired('mục tiêu'),
  action_rule_grant_coin: typingRequired('hành động'),
  target_value: typingRequired('mục tiêu hoàn thành'),
  video: chooseRequired('video'),

  start_date: chooseRequired('ngày bắt đầu'),
  end_date: chooseRequired('ngày kết thúc'),
  oldpassword: 'Vui lòng nhập mật khấu hiện tại',
  newpassword: 'Vui lòng nhập mật khấu mới',
  passwordValid: 'Mật khấu ít nhất 8 ký tự',
  reNewPassword: 'Vui lòng nhập lại mật khấu mới',
  notMatchNewPassword: 'Mật khấu mới không khớp',
  //NUMBER
  number: typingRequired('số'),
  numberInteger: typingRequired('số nguyên'),
  //IMAGE
  imageSize: 'Vui lòng chọn 4 ảnh',
  image: 'Vui lòng chọn ảnh',
  color: 'Vui lòng chọn màu',
  focus: 'trọng điểm',

  //USER
  id: 'Vui lòng nhập id',
  level: 'Vui lòng nhập lớp',
  levelValid: 'Lớp hợp lệ từ 1 đến 20',
  email: 'Vui lòng nhập email',
  emailValid: 'Vui lòng nhập email hợp lệ',

  name: (name) => `Vui lòng nhập ${name}`,
  nameValid: (name) => `${_.upperFirst(name)} không hợp lệ`,
  nameLengthValid: (name, length) => `${_.upperFirst(name)} tối đa ${length} ký tự`,

  onlySymbols: 'Không được chứa toàn ký tự đặc biệt',
  onlyNumberAndSymbols: 'Không được chứa toàn ký tự đặc biệt và số',
  onlyNumber: 'Không được chứa toàn số',
  relation: 'Vui lòng chọn quan hệ',
  typeUser: 'Vui lòng chọn loại tài khoản',
  birthday: 'Vui lòng chọn ngày sinh',
  status: 'Vui lòng chọn trạng thái',
  gender: 'Vui lòng chọn giới tính',
  address: 'Vui lòng nhập địa chỉ',
  school_name: typingRequired('tên trường'),
  subject_responsible: typingRequired('chuyên khoa'),
  introduce: 'Vui lòng nhập lời giới thiệu',
  course: 'Vui lòng chọn lớp học',
  school: 'Vui lòng nhập trường học',

  phone: 'Vui lòng nhập số điện thoại',
  phoneValid: 'Vui lòng nhập số điện thoại hợp lệ',

  //ROLE
  role: 'Vui lòng chọn quyền',
  description: 'Vui lòng nhập mô tả',
  desLengthValid: 'Mô tả tối đa 50 ký tự',
  descriptionValid: 'Mô tả không hợp lệ',
  verison: 'Vui lòng nhập phiên bản',

  //COIN
  priceInteger: 'Vui lòng nhập giá',
  recommendation: chooseRequired('gợi ý'),
  coinCategory: 'Vui lòng chọn loại vật phẩm',
  //step
  step: 'Bước này đã tồn tại',

  //ui default
  uiDefault: 'Lớp và giới tính này đã tồn tại',
  existsName: 'Tên này đã tồn tại',

  //Data entry
  subject: chooseRequired('môn học'),
  typeProgram: 'Vui lòng chọn kiểu chương trình',
  program: 'Vui lòng chọn chương trình',
  subjectCateory: 'Vui lòng chọn loại môn học',
  category_key: 'Vui lòng chọn key',
  item_tab_code: 'Vui lòng chọn tab',
  semester: 'Vui lòng chọn học kỳ',
  typeQuestion: 'Vui lòng chọn loại câu hỏi',
  difficultLevel: 'Vui lòng chọn độ khó',
  correctAnswer: 'Vui lòng nhập câu trả lời đúng',
  answerExplain: 'Vui lòng nhập câu trả lời đúng',
  poin: 'Vui lòng nhập poin',
  poinValid: 'Poin phải là số nguyên dương',
  //quest
  questCategory: 'Vui lòng chọn loại nhiệm vụ',
  typeDisplayQuestCategory: 'Vui lòng chọn hình thức hiển thị',
  repeat: 'Vui lòng chọn hình thức lặp lại',
  action_type: 'hoạt động nhận điểm',
  key: typingRequired("key"),
  coin: 'Vui lòng nhập coin',
  targetType: 'Vui lòng chọn cách thức nhận điểm',
  coinValid: 'Coin phải là số nguyên dương',
  fileRequired: chooseRequired('file'),

  //IMAGE
  avatar: 'Dung lượng ảnh tối đa là 2 MB',
  background: 'Dung lượng ảnh tối đa là 5 MB',
  //IMAGE FOLDER
  folder_avatar: 'Mỗi ảnh tối đa là 2 MB',
  folder_background: 'Mỗi ảnh tối đa là 5 MB',

  //FILE MEDIA
  media: 'Dung lượng file tối đa là 10GB',
  folder_media: 'Dung lượng mỗi file tối đa là 10GB',
  //VERSION
  linkValid: 'Link bắt đầu bằng http:// hoặc https://',
  link: 'Vui lòng nhập link',
  urlVideo: 'Vui lòng nhập url video',

  //USER GUIDE
  stepInteger: 'Vui lòng nhập bước',

  //DEVICE
  device_running: 'Không thể xóa thiết bị đang hoạt động',

  policy: 'Vui lòng nhập nội dung điều khoản',
  faq: 'Vui lòng nhập nội dung FAQ',

  //teacher
  teacher: chooseRequired('giáo viên'),
  //DATA ENTRY
  lesson: 'Vui lòng nhập bài học',
  chapter: 'Vui lòng chọn chương học',
  chapterType: 'Vui lòng chọn kiểu chương học',
  topic: 'Vui lòng chọn topic',
  question: 'Vui lòng chọn câu hỏi',
  answer: 'Vui lòng chọn câu trả lời',
  typeAnswer: 'Vui lòng chọn kiểu câu trả lời',
  positionAnswer: 'Vui lòng chọn vị trí câu trả lời',
  valueAnswer: 'Vui lòng nhập giá trị câu trả lời',
  order: 'Vui lòng nhập số thứ tự',
  minOrder: 'Số thứ tự lớn hơn 0',
  maxOrder: 'Số thứ tự nhỏ hơn 10',
  min_target_quantity: 'Số lượng yêu cầu lớn hơn hoặc bằng 0',

  //video
  durationVideo: 'Vui lòng nhập thời lượng video',
  durationVideoValid: 'Thời lượng phải là số nguyên dương',
  content: 'Vui lòng nhập nội dung',
  title: 'Vui lòng nhập tiêu đề',
  titleValid: 'Tiêu đề không hợp lệ',
  titleLengthValid: 'Tiêu đề tối đa 50 ký tự',
  timeTrigger: 'Vui lòng nhập thời gian xuất hiện',
  time: 'Vui lòng nhập thời gian',
  duration: 'Vui lòng nhập thời gian',
  videoURL: 'Vui lòng nhập url video',

  //config
  type_faq: chooseRequired('loại câu hỏi thường gặp'),

  author: 'Vui lòng nhập tên tác giả',
  book: 'Vui lòng chọn sách',
  typeBook: 'Vui lòng chọn kiểu sách',
  voice: chooseRequired('file âm thanh'),
  working_time: typingRequired('thời gian làm việc'),

  kieubai: 'Vui lòng chọn kiểu bài',
  dangbai: 'Vui lòng chọn dạng bài',

  choose: chooseRequired,
  input: typingRequired,
  inputInValid: (name) => `${_.upperFirst(name)} không hợp lệ`,
}

export default validateError