import React, {useCallback, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {UserOutlined} from '@ant-design/icons'
import {Avatar, Dropdown, Menu} from 'antd'
import styles from './style.module.scss'
import {logout} from '../../../../../services/actions/auth'
import {Link} from 'react-router-dom'
import Utils from '../../../../../utils'

const mapStateToProps = ({user}) => ({user})

const ProfileMenu = ({dispatch, user}) => {
  const [count, setCount] = useState(7)
  const pathAvatar = useSelector(state => state.user.avatar)
  const avatarUrl = Utils.getLinkImage(pathAvatar)

  const logoutCallback = useCallback((e) => {
    dispatch(logout())
  }, [dispatch])

  const handleLogout = (e) => {
    e.preventDefault()
    logoutCallback()
  }


  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>Xin chào, {user.full_name || 'Anonymous'}</strong>
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item>
        <div>
          <strong>Email: </strong>
          {user.email || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item>
        <Link to="/auth/change_password">
          <i className='fe fe-lock mr-2'/>
          Đổi mật khẩu
        </Link>
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item>
        <a href='#' onClick={handleLogout}>
          <i className='fe fe-log-out mr-2'/>
          Đăng xuất
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Avatar
          src={avatarUrl}
          className={styles.avatar}
          shape='square'
          size='large'
          icon={<UserOutlined/>}
        />
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
