import { NOTI_MESSAGE_LEARN_REMINDER_TYPE, NOTI_MESSAGE_NORMAL_TYPE, NOTI_MESSAGE_SUGGEST_COURSE_TYPE, NOTI_PERIODIC, NOTI_RESERVE } from "./index"

export const yupType = {
  name: 'tên',
  title: 'tiêu đề',
  description: 'mô tả',
  short_description: 'mô tả ngắn',
  content: 'nội dung',
  coin: 'điểm thưởng',
  correct_ratio: 'tỷ lệ đúng'

}

/**
 * action in form
 * @type {{DELETE: string, CREATE: string, EDIT: string, DETAIL: string, VIEW: string, IMPORT: string, EXPORT: string}}
 */
export const ACTION_TYPE = {
  EDIT: 'edit',
  CREATE: 'create',
  IMPORT: 'import',
  EXPORT: 'export',
  UPLOAD: 'UPLOAD',
  DETAIL: 'detail',
  DELETE: 'delete',
  VIEW: 'view',
  APPROVE: 'approve',
  CHOOSE: 'choose',
  detail_question: 'detail_question',
  EDITOR: 'editor',
  ASSIGN: 'assign',
  PREVIEW: 'preview'
}

/**
 * key in configuration to call api
 * @type {{AVATAR_DEFAULT: string, UI_DEFAULT: string, USER_GUIDE: string, VERSION_LAUNCHER: string, POLICY_PRIVACY: string, BACKGROUND: string, NEXTA_INFO: string}}
 */
export const CONFIG_KEY = {
  AVATAR_DEFAULT: 'AVATAR_DEFAULT',
  FRAME: 'FRAME',
  BACKGROUND: 'BACKGROUND_DEFAULT',
  UI_DEFAULT: 'UI_DEFAULT',
  POLICY_PRIVACY: 'POLICY_PRIVACY',
  FAQ_PARENT: 'FAQ_PARENT',
  FAQ_PUPIL: 'FAQ_PUPIL',
  USER_GUIDE: 'USER_GUIDE',
  VERSION_LAUNCHER: 'VERSION_LAUNCHER',
  NEXTA_INFO: 'NEXTA_INFO'
}

/**
 * title show in form when show modal
 */
export const NAME_FORM = {
  admin: 'tài khoản admin',
  role: 'quyền',
  parent: 'phụ huynh',
  clickEditor: 'Tạo chương',
  pupil: 'học sinh',
  teacher: 'giáo viên',
  device_parent: 'thiết bị phụ huynh',
  device_pupil: 'thiết bị học sinh',
  coin: 'vật phẩm coin',
  type_coin: 'kiểu vật phẩm',
  coin_type: 'loại vật phẩm',
  item_type: 'kiểu vật phẩm',
  quest: 'nhiệm vụ',
  quest_type: 'loại nhiệm vụ',
  continuous: 'liên tục',
  event: 'sự kiện todo',
  level: 'lớp học',
  course: 'lớp học',
  course_level: 'khối học',
  program: 'chương trình',
  background: 'hình nền mặc định',
  guide: 'hướng dẫn sử dụng',
  condition: 'điều khoản',
  issue: 'khiếu nại',
  issue_type: 'loại vấn đề',
  version_launcher: 'version launcher',
  version_parent_app: 'version parent app',
  version_school_app: 'version school app',
  version_lite_app: 'version lite app',
  ui_default: 'UI mặc định',
  avatar_default: 'avatar mặc định',
  frame: 'khung',
  topic: 'topic',
  voice: 'voice',
  lesson: 'bài học',
  lesson_exercise: 'bài tập thực hành',
  lesson_theory: 'phân bổ lý thuyết',
  lesson_video: 'phân bổ video',
  lesson_video_new: 'video',
  assign_lesson_video: 'video tham khảo',
  subject: 'môn học',
  subject_category: 'loại môn học',
  chapter: 'chương học',
  chapter_example: 'bài tập kiểm tra',
  topic_chapter: 'topic chương học',
  question: 'câu hỏi',
  question_image: 'hình ảnh câu hỏi',
  question_media: 'thư viện media',
  book: 'sách',
  book_chapter: 'sách - chương',
  theory: 'lý thuyết',
  theory_section: 'đầu mục lý thuyết',
  preview_h5p: 'Preview H5P',
  frequently_question: 'câu hỏi thường gặp',
  user_guide: 'hướng dẫn sử dụng',
  rule_grant_coin: 'tiêu chi thưởng coin',
  developer_information: 'thông tin nhà phát triển',
  video: 'video',
  reference_video: 'video tham khảo',
  video_section: 'đầu mục video',
  video_exercise: 'bài tập video',
  answer: 'câu trả lời',
  semester: 'học kỳ',
  video_extend: 'video mở rộng',
  app_extend: 'ứng dụng mở rộng',
  app_next_a: 'ứng dụng NextA',
  image_ch_play: 'hình ảnh CH play',
  batch: 'lô',
  kieubai: 'kiểu bài',
  dangbai: 'dạng bài',
  baitlv: 'bài tập làm văn',
  debai: 'đề bài',
  huongdantlv: 'hướng dẫn',
  video_tham_khao: 'video tham khảo',
  danh_muc_video_tham_khao: 'danh mục video tham khảo',
  sach_tham_khao: 'sách tham khảo',
  danh_muc_sach_tham_khao: 'danh mục sách tham khảo',
  video_tlv: 'video tập làm văn',
  notification: 'thông báo',
  school: "trường",
  pupil: "học sinh",
  
  bai_giang_dien_tu: "bài giảng điện tử",
  website: "website",
  ung_dung: "ứng dụng",
  tro_choi: "trò chơi",
  hoc_leu: "học liệu",
  ke_hoach_giang_day: "kế hoạch giảng dạy",
  de_thi_tham_khao: "đề thi tham khảo",
  chu_de: "chủ đề",
  thu_vien: "thư viện",
  danh_muc: "danh mục",
  challenge :"thử thách",

  simulation :"phòng thí nghiệm ảo"
}

/**
 * status in issue page
 * @type {*[]}
 */
export const STATUS_ISSUE = [
  {
    key: 0,
    value: 'Chưa phản hồi'
  },
  {
    key: 1,
    value: 'Đang xử lý'
  },
  {
    key: 2,
    value: 'Đã phản hồi'
  }
]

export const REPEAT_QUEST_HIDE = [
  {
    key: 0,
    value: 'Sự kiện'
  }
]

export const REPEAT_QUEST = [
  {
    key: 0,
    value: 'Sự kiện'
  },
  {
    key: 1,
    value: 'Hàng ngày'
  }
]

/**
 * type quest in quest page
 * @type {*[]}
 */
export const TYPE_QUEST = [
  {
    key: 0,
    value: 'Nhiệm vụ ẩn'
  },
  {
    key: 1,
    value: 'Nhiệm vụ công khai'
  }
]

/**
 * gender
 * @type {*[]}
 */
export const GENDER_FULL = [
  {
    key: 0,
    value: 'Nam'
  },
  {
    key: 1,
    value: 'Nữ'
  },
  {
    key: 2,
    value: 'Khác'
  }
]

export const GENDER = [
  {
    key: 0,
    value: 'Nam'
  },
  {
    key: 1,
    value: 'Nữ'
  }
]

export const content_browsing = [
  {
    key: 0,
    value: 'Không được duyệt bài'
  },
  {
    key: 1,
    value: 'Được duyệt bài'
  }
]

/**
 * type background
 * @type {{image: number, color: number}}
 */
export const TYPE_UI_DEFAULT = {
  color: 0,
  image: 1
}

export const OS_DEFAULT = [
  {
    key: 0,
    value: 'Android'
  },
  {
    key: 1,
    value: 'IOS'
  }
]


export const TYPE_PARENT = [
  {
    key: 0,
    value: 'Chính'
  },
  {
    key: 1,
    value: 'Phụ'
  }
]

export const RELATION_PARENT = [
  {
    key: 0,
    value: 'Bố'
  },
  {
    key: 1,
    value: 'Mẹ'
  },
  {
    key: 2,
    value: 'Khác'
  },
  {
    key: 3,
    value: 'Ông'
  },
  {
    key: 4,
    value: 'Bà'
  },
  {
    key: 5,
    value: 'Giáo viên'
  },
]

export const STATUS_USER = [
  {
    key: 0,
    value: 'Ngưng hoạt động'
  },
  {
    key: 1,
    value: 'Đang hoạt động'
  }
]

export const ACTION_RULE_GRANT_COIN = [
  {
    key: 1,
    value: 'Làm bài tập'
  },
  {
    key: 2,
    value: 'Làm bài kiểm tra'
  }
]

export const TYPE_RULE_GRANT_COIN = [
  {
    key: 1,
    value: 'Hoàn thành câu hỏi'
  },
  {
    key: 2,
    value: 'Trả lời chính xác'
  },
  {
    key: 3,
    value: 'Hoàn thành nhanh'
  }
]

export const TYPE_FAQ = [
  {
    key: 0,
    value: 'Học sinh'
  },
  {
    key: 1,
    value: 'Phụ huynh'
  }
]

export const STATUS_PARENT = [
  {
    key: 0,
    value: 'Ngưng hoạt động'
  },
  {
    key: 1,
    value: 'Đang hoạt động'
  },
  {
    key: 2,
    value: 'Chưa xác thực'
  }
]

export const TYPE_PROGRAM = [
  {
    key: 'CO_BAN',
    value: 'Cơ bản'
  },
  {
    key: 'NANG_CAO',
    value: 'Nâng cao'
  },
  {
    key: 'THI_QUOC_TE',
    value: 'Thi quốc tế'
  },
  {
    key: 'THI_OLYMPIC',
    value: 'Thi Olympic'
  },
  {
    key: 'KHAN_ACADEMY',
    value: 'Khan Academy'
  }
]

export const CLASS_NAME = [
  {
    key: 'lop_1',
    value: 'Lớp 1'
  },
  {
    key: 'lop_2',
    value: 'Lớp 2'
  },
  {
    key: 'lop_3',
    value: 'Lớp 3'
  },
  {
    key: 'lop_4',
    value: 'Lớp 4'
  },
  {
    key: 'lop_5',
    value: 'Lớp 5'
  }
]

export const SUBJECT_NAME = [
  {
    key: 'toan',
    value: 'Toán'
  },
  {
    key: 'tieng_viet',
    value: 'Tiếng Việt'
  },
  {
    key: 'tieng_anh',
    value: 'Tiếng Anh'
  },
]

export const BOOK_NAME = [
  {
    key: 'canh_dieu',
    value: 'Cánh diều'
  },
  {
    key: 'co_ban',
    value: 'Cơ bản'
  },
  {
    key: 'ket_noi',
    value: 'Kết nối'
  },
]


export const EVENT_KEY = [
  {
    key: 'LAM_BAI_TAP',
    value: 'LAM_BAI_TAP'
  },
  {
    key: 'LAM_BAI_KIEM_TRA',
    value: 'LAM_BAI_KIEM_TRA'
  },
  {
    key: 'XEM_VIDEO',
    value: 'XEM_VIDEO'
  },
  {
    key: 'HOC_LY_THUYET',
    value: 'HOC_LY_THUYET'
  }
]

export const DIFFICULT_LEVEL = [
  {
    key: 0,
    value: 'Chưa xác định'
  },
  {
    key: 1,
    value: 'Nhận biết'
  },
  {
    key: 2,
    value: 'Thông hiểu'
  },
  {
    key: 3,
    value: 'Vận dụng'
  }
]

export const ADMIN_APPROVE = [
  {
    key: 0,
    value: 'Chờ duyệt'
  },
  {
    key: 1,
    value: 'Đã duyệt'
  }
]

export const ACTION_TYPE_QUEST_HIDE = [
  {
    key: 6,
    value: 'Tạo tài khoản lần đầu tiên'
  },
  {
    key: 7,
    value: 'Làm bài tập lần đầu tiên'
  },
  {
    key: 8,
    value: 'Làm bài kiểm tra lần đầu tiên'
  },
  {
    key: 9,
    value: 'Học lý thuyết lần đầu tiên'
  },
  {
    key: 10,
    value: 'Xem video lần đầu tiên'
  },
  {
    key: 11,
    value: 'Mua vật phẩm lần đầu tiên'
  },
  {
    key: 12,
    value: 'Đánh giá lần đầu tiên'
  },
  {
    key: 13,
    value: 'Kết nối với phụ huynh'
  },
  {
    key: 14,
    value: 'Thay avatar lần đầu tiên'
  }
]

export const ACTION_TYPE_QUEST_PUBLIC = [
  {
    key: 101,
    value: 'Xem video'
  },
  {
    key: 102,
    value: 'Làm bài tập'
  },
  {
    key: 103,
    value: 'Học lý thuyết'
  },
  {
    key: 104,
    value: 'Làm bài kiểm tra'
  }
]


export const ACTION_TYPE_QUEST_OTHER = [
  {
    key: 201,
    value: 'Mở máy trong ngày'
  },
  {
    key: 202,
    value: 'Chơi game'
  }
]


export const QUEST_CONTINUES = [
  {
    key: 0,
    value: 'Không'
  },
  {
    key: 1,
    value: 'Có'
  }
]

export const RANDOM_QUESTION = [
  {
    key: false,
    value: 'Không'
  },
  {
    key: true,
    value: 'Có'
  }
]

export const THEORY_FOCUS = [
  {
    key: 0,
    value: 'Không'
  },
  {
    key: 1,
    value: 'Có'
  }
]

export const RECOMMENDATION = [
  {
    key: 0,
    value: 'Không'
  },
  {
    key: 1,
    value: 'Có'
  }
]

export const YES_NO_OPTION = [
  {
    key: 0,
    value: 'Không'
  },
  {
    key: 1,
    value: 'Có'
  }
]

export const QUEST_CATE = {
  hide: 'Nhiệm vụ ẩn',
  study: 'Học tập',
  other: 'Khác'
}

export const QUEST_CATE_KEY = {
  hide: 'NHIEM_VU_AN',
  study: 'HOC_TAP',
  other: 'NHIEM_VU_KHAC'
}

export const IS_COIN_ITEM_DEFAULT = [
  {
    key: true,
    value: 'Item mặc định'
  },
  {
    key: false,
    value: 'Item có thể mua'
  }
]

export const CATEGORY_KEY_ITEM = [
  {
    key: 'AVATAR',
    value: 'AVATAR'
  },
  {
    key: 'FRAME',
    value: 'FRAME'
  },
  {
    key: 'BACKGROUND',
    value: 'BACKGROUND'
  }
]

export const CATEGORY_TAB_ITEM = [
  {
    key: 0,
    value: 'TAB0'
  },
  {
    key: 1,
    value: 'TAB1'
  },
  {
    key: 2,
    value: 'TAB2'
  },
  {
    key: 3,
    value: 'TAB3'
  },
]

export const QUEST_KEY_ITEM = [
  {
    key: 'NHIEM_VU_AN',
    value: 'NHIEM_VU_AN'
  },
  {
    key: 'HOC_TAP',
    value: 'HOC_TAP'
  },
  {
    key: 'NHIEM_VU_KHAC',
    value: 'NHIEM_VU_KHAC'
  }
]

export const ITEM_TYPE_AVATAR = [
  {
    key: 'VUI_VE',
    value: 'VUI_VE'
  },
  {
    key: 'BAN_NAM',
    value: 'BAN_NAM'
  },
  {
    key: 'CUTE',
    value: 'CUTE'
  },
  {
    key: 'BAN_NU',
    value: 'BAN_NU'
  }
]
export const ITEM_TYPE_BACKGROUND = [
  {
    key: 'ILUSTRATOR',
    value: 'ILUSTRATOR'
  },
  {
    key: 'MAU_SAC',
    value: 'MAU_SAC'
  },
  {
    key: 'PHONG_CANH',
    value: 'PHONG_CANH'
  },
  {
    key: 'CHIBI',
    value: 'CHIBI'
  }
]
export const ITEM_TYPE_FRAME = [
  {
    key: 'MANH_ME',
    value: 'MANH_ME'
  },
  {
    key: 'HAI_HOA',
    value: 'HAI_HOA'
  },
  {
    key: 'FULL_COLOR',
    value: 'FULL_COLOR'
  },
  {
    key: 'BLACK_WHITE',
    value: 'BLACK_WHITE'
  }
]


export const APPROVE = {
  allow: 1,
  deny: 0
}

export const BOOK_TYPE = [
  {
    key: 0,
    value: 'Sách mặc định'
  },
  {
    key: 1,
    value: 'Sách bình thường'
  },
  {
    key: 2,
    value: 'Sách nhà trường'
  }
]

export const H5P_TYPE = [
  {
    key: 1,
    value: 'Interactive video'
  },
  {
    key: 2,
    value: 'Interactive book'
  },
  {
    key: 3,
    value: 'Interactive course'
  }
]

export const NOTI_TYPE = [
  {
    key: NOTI_PERIODIC,
    value: "Định kỳ"
  },
  {
    key: NOTI_RESERVE,
    value: "Đặt trước"
  },
]


export const NOTI_MESSAGE_TYPE = [
  {
    key: NOTI_MESSAGE_LEARN_REMINDER_TYPE,
    value: "Nhắc nhở học tập"
  },
  {
    key: NOTI_MESSAGE_SUGGEST_COURSE_TYPE,
    value: "Quảng cáo khóa học"
  },
  {
    key: NOTI_MESSAGE_NORMAL_TYPE,
    value: "Thông báo chung"
  },
]