import { all, call, put, takeEvery } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import { actionTypes } from '../actionTypes'

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: actionTypes.SET_STATE_MENU,
    payload: {
      menuData,
    },
  })
}

export function* UPDATE_MESSGAE_UNSEEN_NUMBER({ payload: { num } }) {
  const menuData = yield call(getMenuData)
  for (const m of menuData) {
    if (m.key == 'chat') {
      m.count = num
    }
  }

  yield put({
    type: actionTypes.SET_STATE_MENU,
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), 
    takeEvery(actionTypes.UPDATE_NOTI_MESSAGE, UPDATE_MESSGAE_UNSEEN_NUMBER),
  ])
}
