import {actionTypes} from '../actionTypes'

const initialState = {
  id: '',
  full_name: '',
  email: '',
  avatar: '',
  authorized: false, // false is default value
  loading: false,
  role: null
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE_USER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
