import apiClient from '../axios'
import {notification} from 'antd'

/**
 * set loading = true
 * @param type
 * @returns {Function}
 */
export const startFetching = (type) => {
  return dispatch => {
    dispatch({
      type: type,
      payload: {
        loading: true
      }
    })
  }
}

/**
 * set loading = false
 * @param type
 * @returns {Function}
 */
export const stopFetching = (type) => {
  return dispatch => {
    dispatch({
      type: type,
      payload: {
        loading: false
      }
    })
  }
}

/**
 * load data from api
 * @param api: path api
 * @param actionType
 * @returns {Function}
 */
export const loadData = ({api, actionType}) => {
  return async (dispatch) => {
    dispatch(startFetching(actionType))
    try {
      const res = await apiClient.get(`${api}`)
      if (res && res.data) {
        dispatch({
          type: actionType,
          payload: {
            data: res.data.data,
            isCreateSuccess: false,
            isUpdateSuccess: false,
            isRemoveSuccess: false
          }
        })
      }
    } catch (error) {

    }
    dispatch(stopFetching(actionType))
  }
}

/**
 * api create
 * @param api
 * @param data
 * @param actionType
 * @returns {Function}
 */
export const createData = ({api, data, actionType}) => {
  return async (dispatch) => {
    dispatch(startFetching(actionType))
    try {
      const res = await apiClient.post(api, data)
      if (res && res.data) {
        notification.success({
          message: 'Tạo thành công!',
          // description: 'Tạo thành công!'
        })
        dispatch({
          type: actionType,
          payload: {
            isCreateSuccess: true,
          }
        })
      }
    } catch (error) {

    }
    dispatch(stopFetching(actionType))
  }
}

/**
 * api update
 * @param api
 * @param data
 * @param actionType
 * @returns {Function}
 */
export const updateData = ({api, data, actionType}) => {
  return async (dispatch) => {
    dispatch(startFetching(actionType))
    try {
      const res = await apiClient.put(api, data)
      if (res && res.data) {
        notification.success({
          message: 'Cập nhật thành công!',
          // description: 'Cập nhật thành công!'
        })
        dispatch({
          type: actionType,
          payload: {
            isUpdateSuccess: true,
          }
        })
      }
    } catch (error) {

    }
    dispatch(stopFetching(actionType))
  }
}

/**
 * api detail
 * @param api
 * @param actionType
 * @returns {Function}
 */
export const detailData = ({api, actionType}) => {
  return async (dispatch) => {
    dispatch(startFetching(actionType))
    try {
      const res = await apiClient.get(api)
      if (res && res.data) {
        dispatch({
          type: actionType,
          payload: {
            detail: res.data.data,
          }
        })
      }
    } catch (error) {

    }
    dispatch(stopFetching(actionType))
  }
}

/**
 * api remove
 * @param api
 * @param actionType
 * @returns {Function}
 */
export const removeData = ({api, actionType}) => {
  return async (dispatch) => {
    dispatch(startFetching(actionType))
    try {
      const res = await apiClient.delete(api)
      if (res && res.data) {
        notification.success({
          message: 'Xóa thành công!',
          // description: 'Xóa thành công!'
        })
        dispatch({
          type: actionType,
          payload: {
            isRemoveSuccess: true,
          }
        })
      }
    } catch (error) {

    }
    dispatch(stopFetching(actionType))
  }
}