import axios from 'axios'
import store from 'store'
import {notification} from 'antd'
import {store as storeRedux} from '../../index'
import {actionTypes} from '../../redux/actionTypes'

const BASE_URL = process.env.REACT_APP_BASE_URL
const PARENT_BASE_URL = process.env.REACT_APP_PARENT_BASE_URL

// const BASE_URL = 'http://nexta-cms-api.tinhvan.com'
// const BASE_URL = 'http://192.168.53.191:3000'
// const BASE_URL = 'http://192.168.53.142:3000'
// const BASE_URL = 'http://192.168.53.69:3000'
// const BASE_URL = 'http://10.1.48.68:3000'
// const BASE_URL = 'http://192.168.50.171:3000'
const API_KEY = process.env.REACT_APP_API_KEY

const axiosConfig = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Origin, Accept, X-Requested-With, Content-Type, Authorization, Access-Control-Allow-Headers',
    'Access-Control-Allow-Method': "*",
    'Access-Control-Allow-Origin': "*",
    'api-key': API_KEY
  },
  timeout: 10 * 60 * 1000
}

const apiClient = axios.create({
  baseURL: BASE_URL,
  ...axiosConfig
})

const apiParent = axios.create({
  baseURL: PARENT_BASE_URL,
  ...axiosConfig
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `${accessToken}`
    // request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  try {
    const {response} = error
    console.log("response..........", response)
    if (!response) {
      notification.warning({
        message: 'Có lỗi xảy ra, vui lòng thử lại'
      })
    }
    if (response && response.data) {
      const message = response.data.message
      if (typeof message === 'string') {
        notification.warning({
          message: response.data.message
        })
      }

    }
    if (response && (response.status === 401)) {
      store.remove('accessToken')
      storeRedux.dispatch({
        type: actionTypes.LOGOUT,
      })
      // history.push('/auth/login')
    }
  }catch (e) {

  }
})

apiParent.interceptors.response.use(undefined, error => {
  // Errors handling
  try {
    const {response} = error
    console.log("response..........", response)
    if (!response) {
      notification.warning({
        message: 'Có lỗi xảy ra, vui lòng thử lại'
      })
    }
    if (response && response.data) {
      const message = response.data.message
      if (typeof message === 'string') {
        notification.warning({
          message: response.data.message
        })
      }
    }

  }catch (e) {

  }
})

export default apiClient
export {
  apiParent
}
