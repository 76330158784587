import { SUPER_ADMIN } from '../constants'
import _ from 'lodash'
import { ACTION_TYPE } from '../constants/form'
import { isRequired, RoleUtils } from './index'

export const getObjectRoleForm = (values) => {
  if (!values) return {}
  let roleValue = {}
  console.log('call')
  let user = {}
  let device = {}
  let category = {}
  let content_learning = {}
  let bai_hoc = {}
  let products = {}
  let extend = {}
  let configurations = {}
  let sysparam = {}
  let school = {}

  if (!_.isEmpty(values.dashboard)) {
    roleValue.dashboard = values.dashboard
  }

  if (!_.isEmpty(values.challenge)) {
    roleValue.challenge = values.challenge
  }

  if (!_.isEmpty(values.chat)) {
    roleValue.chat = values.chat
  }

  if (!_.isEmpty(values.challenge)) {
    roleValue.notification = values.notification
  }
  //School
  if (!_.isEmpty(values.school)) {
    school.root = values.school
  }

  if (!_.isEmpty(values.pupil_school)) {
    school.pupil = values.pupil_school
  }

  if (!_.isEmpty(values.teacher_school)) {
    school.teacher = values.teacher_school
  }

  if (!_.isEmpty(values.class_school)) {
    school.class = values.class_school
  }

  //user
  if (!_.isEmpty(values.admin)) {
    user.admin = values.admin
  }
  if (!_.isEmpty(values.pupil)) {
    user.pupil = values.pupil
  }
  if (!_.isEmpty(values.parent)) {
    user.parent = values.parent
  }
  if (!_.isEmpty(values.teacher)) {
    user.teacher = values.teacher
  }
  //device
  if (!_.isEmpty(values.device_pupil)) {
    device.device_pupil = values.device_pupil
  }
  if (!_.isEmpty(values.device_parent)) {
    device.device_parent = values.device_parent
  }
  //select
  if (!_.isEmpty(values.course)) {
    category.course = values.course
  }
  if (!_.isEmpty(values.quest)) {
    category.quest = values.quest
  }
  if (!_.isEmpty(values.event)) {
    category.event = values.event
  }
  if (!_.isEmpty(values.semester)) {
    category.semester = values.semester
  }
  if (!_.isEmpty(values.program)) {
    category.program = values.program
  }
  // content_learning
  if (!_.isEmpty(values.theory)) {
    content_learning.theory = values.theory
  }
  if (!_.isEmpty(values.video)) {
    content_learning.video = values.video
  }
  if (!_.isEmpty(values.question)) {
    content_learning.question = values.question
  }
  if (!_.isEmpty(values.question_media)) {
    content_learning.question_media = values.question_media
  }
  // if (!_.isEmpty(values.question_media_image)) {
  //   content_learning.question_media_image = values.question_media_image
  // }
  // if (!_.isEmpty(values.question_media_voice)) {
  //   content_learning.question_media_voice = values.question_media_voice
  // }
  // if (!_.isEmpty(values.question_media_video)) {
  //   content_learning.question_media_video = values.question_media_video
  // }
  if (!_.isEmpty(values.taplamvan)) {
    content_learning.taplamvan = values.taplamvan
  }
  // if (!_.isEmpty(values.taplamvan_debai)) {
  //   content_learning.taplamvan_debai = values.taplamvan_debai
  // }
  // if (!_.isEmpty(values.taplamvan_dangbai)) {
  //   content_learning.taplamvan_dangbai = values.taplamvan_dangbai
  // }
  // if (!_.isEmpty(values.taplamvan_huongdan)) {
  //   content_learning.taplamvan_huongdan = values.taplamvan_huongdan
  // }
  // if (!_.isEmpty(values.taplamvan_kieubai)) {
  //   content_learning.taplamvan_kieubai = values.taplamvan_kieubai
  // }
  if (!_.isEmpty(values.question_compilation)) {
    content_learning.question_compilation = values.question_compilation
  }
  //bai_hoc
  if (!_.isEmpty(values.voice)) {
    bai_hoc.voice = values.voice
  }
  if (!_.isEmpty(values.subject)) {
    bai_hoc.subject = values.subject
  }
  if (!_.isEmpty(values.topic)) {
    bai_hoc.topic = values.topic
  }
  if (!_.isEmpty(values.chapter)) {
    bai_hoc.chapter = values.chapter
  }
  if (!_.isEmpty(values.lesson)) {
    bai_hoc.lesson = values.lesson
  }
  //product
  if (!_.isEmpty(values.coin)) {
    products.coin = values.coin
  }
  if (!_.isEmpty(values.book)) {
    products.book = values.book
  }
  // if (!_.isEmpty(values.book_chapter)) {
  //   products.book_chapter = values.book_chapter
  // }
  // if (!_.isEmpty(values.book_lesson)) {
  //   products.book_lesson = values.book_lesson
  // }
  // if (!_.isEmpty(values.book_theory)) {
  //   products.book_theory = values.book_theory
  // }
  // if (!_.isEmpty(values.book_video)) {
  //   products.book_video = values.book_video
  // }
  // if (!_.isEmpty(values.book_theory_item)) {
  //   products.book_theory_item = values.book_theory_item
  // }
  // if (!_.isEmpty(values.book_video_item)) {
  //   products.book_video_item = values.book_video_item
  // }
  // if (!_.isEmpty(values.book_video_exercise)) {
  //   products.book_video_exercise = values.book_video_exercise
  // }
  //extend
  if (!_.isEmpty(values.app_extend)) {
    extend.app_extend = values.app_extend
  }
  if (!_.isEmpty(values.video_extend)) {
    extend.video_extend = values.video_extend
  }
  //config
  if (!_.isEmpty(values.ui)) {
    configurations.ui = values.ui
  }
  if (!_.isEmpty(values.policy)) {
    configurations.policy = values.policy
  }
  if (!_.isEmpty(values.faq)) {
    configurations.faq = values.faq
  }
  if (!_.isEmpty(values.guide)) {
    configurations.guide = values.guide
  }
  if (!_.isEmpty(values.rule_grant_coin)) {
    configurations.rule_grant_coin = values.rule_grant_coin
  }
  if (!_.isEmpty(values.information_dev)) {
    configurations.information_dev = values.information_dev
  }

  if (!_.isEmpty(values.image_ch_play)) {
    configurations.image_ch_play = values.image_ch_play
  }
  //issue
  if (!_.isEmpty(values.issue)) {
    roleValue.issue = values.issue
  }
  //reference
  if (!_.isEmpty(values.reference)) {
    roleValue.reference = values.reference
  }
  //version
  if (!_.isEmpty(values.version)) {
    sysparam.version = values.version
  }
  if (!_.isEmpty(values.notification)) {
    sysparam.notification = values.notification
  }
  if (!_.isEmpty(values.app_detail)) {
    roleValue.app_detail = values.app_detail
  }
  //check key
  if (!_.isEmpty(user)) {
    roleValue.user = user
  }
  if (!_.isEmpty(device)) {
    roleValue.device = device
  }
  if (!_.isEmpty(category)) {
    roleValue.category = category
  }
  if (!_.isEmpty(content_learning)) {
    roleValue.content_learning = content_learning
  }
  if (!_.isEmpty(bai_hoc)) {
    roleValue.bai_hoc = bai_hoc
  }
  if (!_.isEmpty(products)) {
    roleValue.products = products
  }
  if (!_.isEmpty(extend)) {
    roleValue.extend = extend
  }
  if (!_.isEmpty(configurations)) {
    roleValue.configurations = configurations
  }
  if (!_.isEmpty(sysparam)) {
    roleValue.sysparam = sysparam
  }
  if (!_.isEmpty(school)) {
    roleValue.school = school
  }

  console.log("Create", values)

  return {
    isRoleEmpty: _.isEmpty(roleValue),
    dataValues: {
      role_name: values.role_name,
      role_description: values.role_description,
      role_value: JSON.stringify(roleValue)
    }
  }
}

export const getDefaultRoleForm = (dataRow, actionType = isRequired('actionType')) => {
  const valueDefault = {
    role_name: null,
    role_description: null,
    role_value: null
  }
  if (!dataRow) return valueDefault
  let role_value = dataRow.role_value
  if (!RoleUtils.isSuperAdmin(dataRow && dataRow.role_value)) {
    role_value = dataRow ? RoleUtils.parseRoleFromApi(dataRow.role_value) : {}
  }
  if (actionType === ACTION_TYPE.EDIT) {
    if (!RoleUtils.isSuperAdmin(role_value) && dataRow) {
      console.log({ role_value }, "eidt")
      return {
        //school
        school: role_value?.school?.root,
        class_school: role_value?.school?.class,
        pupil_school: role_value?.school?.pupil,
        teacher_school: role_value?.school?.teacher,

        role_name: dataRow?.role_name,
        role_description: dataRow?.role_description,
        dashboard: role_value?.dashboard,
        // user
        admin: role_value?.user?.admin,
        teacher: role_value?.user?.teacher,
        pupil: role_value?.user?.pupil,
        parent: role_value?.user?.parent,
        //device
        device_pupil: role_value?.device?.device_pupil,
        device_parent: role_value?.device?.device_parent,
        //cate
        course: role_value?.category?.course,
        quest: role_value?.category?.quest,
        event: role_value?.category?.event,
        semester: role_value?.category?.semester,
        program: role_value?.category?.program,
        //content_learning
        question: role_value?.content_learning?.question,
        question_media: role_value?.content_learning?.question_media,
        // question_media_image: role_value?.content_learning?.question_media_image,
        // question_media_voice: role_value?.content_learning?.question_media_voice,
        // question_media_video: role_value?.content_learning?.question_media_video,
        theory: role_value?.content_learning?.theory,
        video: role_value?.content_learning?.video,
        taplamvan: role_value?.content_learning?.taplamvan,
        // taplamvan_dangbai: role_value?.content_learning?.taplamvan_dangbai,
        // taplamvan_debai: role_value?.content_learning?.taplamvan_debai,
        // taplamvan_kieubai: role_value?.content_learning?.taplamvan_kieubai,
        // taplamvan_huongdan: role_value?.content_learning?.taplamvan_huongdan,
        question_compilation: role_value?.content_learning?.question_compilation,
        //bai_hoc
        voice: role_value?.bai_hoc?.voice,
        subject: role_value?.bai_hoc?.subject,
        topic: role_value?.bai_hoc?.topic,
        chapter: role_value?.bai_hoc?.chapter,
        lesson: role_value?.bai_hoc?.lesson,
        //product
        coin: role_value?.products?.coin,
        book: role_value?.products?.book,
        // book_chapter: role_value?.products?.book_chapter,
        // book_lesson: role_value?.products?.book_lesson,
        // book_theory: role_value?.products?.book_theory,
        // book_video: role_value?.products?.book_video,
        // book_theory_item: role_value?.products?.book_theory_item,
        // book_video_item: role_value?.products?.book_video_item,
        // book_video_exercise: role_value?.products?.book_video_exercise,
        //extend
        app_extend: role_value?.extend?.app_extend,
        video_extend: role_value?.extend?.video_extend,
        //reference
        reference: role_value?.reference,
        //config
        ui: role_value?.configurations?.ui,
        policy: role_value?.configurations?.policy,
        faq: role_value?.configurations?.faq,
        guide: role_value?.configurations?.guide,
        rule_grant_coin: role_value?.configurations?.rule_grant_coin,
        information_dev: role_value?.configurations?.information_dev,
        //issue
        issue: role_value?.issue,
        //param
        version: role_value?.sysparam?.version,
        notification: role_value?.sysparam?.notification,

        chat: role_value?.chat,
        challenge: role_value?.challenge,
        app_detail: role_value?.app_detail,
        image_ch_play: role_value?.configurations?.image_ch_play,
      }
    }
    if (RoleUtils.isSuperAdmin(role_value) && dataRow) {
      return {
        role_name: dataRow.role_name,
        role_description: dataRow.role_description,
        role_value: SUPER_ADMIN
      }
    }
  } else {
    return valueDefault
  }
}

export const parseRoleFromApi = (role) => {
  if (!role) return null
  try {
    let valueRole = JSON.parse(role)
    return _.omitBy(valueRole, _.isEmpty)
  } catch (e) {
    return null
  }
}

export const isSuperAdmin = (roleValue) => {
  if (!roleValue) return false
  return roleValue === SUPER_ADMIN
}

export const getRolePage = (page, role = isRequired('role')) => {
  if (RoleUtils.isSuperAdmin(role)) return {
    isApprove: true,
    isShowAction: true,
    isShowView: true,
    isShowCreate: true,
    isShowEdit: true,
    isShowDelete: true,
    isAllowEditor: true,
    isShowSelect: true,
    isForceDelete: true,
    isShowExport: true,
    isShowImport: true,
    isSuperAdmin: true,
  }
  let roleChecked = {
    isApprove: false,
    isShowAction: false,
    isShowView: false,
    isShowCreate: false,
    isShowEdit: false,
    isShowDelete: false,
    isAllowEditor: false,
    isShowSelect: false,
    isForceDelete: false,
    isShowExport: false,
    isShowImport: false,
    isSuperAdmin: false,
  }

  if (!page || _.isEmpty(page)) return roleChecked
  if (Array.isArray(page)) {
    console.log({ page })
    let pageCopy = [...page]
    
    if (pageCopy.includes(ACTION_TYPE.APPROVE)) {
      roleChecked.isApprove = true
    }
    if (pageCopy.includes(ACTION_TYPE.VIEW)) {
      roleChecked.isShowView = true
    }
    if (pageCopy.includes(ACTION_TYPE.CREATE)) {
      roleChecked.isShowCreate = true
    }
    if (pageCopy.includes(ACTION_TYPE.EDIT)) {
      roleChecked.isShowEdit = true
    }
    if (pageCopy.includes(ACTION_TYPE.IMPORT)) {
      roleChecked.isShowImport = true
    }
    if (pageCopy.includes(ACTION_TYPE.EXPORT)) {
      roleChecked.isShowExport = true
    }
    if (pageCopy.includes(ACTION_TYPE.DELETE)) {
      roleChecked.isShowDelete = true;
      roleChecked.isShowSelect = true;
    }
    if (pageCopy.includes(ACTION_TYPE.EDITOR)) {
      roleChecked.isAllowEditor = true
    }

    pageCopy = pageCopy.filter(item => item !== ACTION_TYPE.CREATE)
    if (pageCopy.length > 0) {
      roleChecked.isShowAction = true
    }

    const numButton = pageCopy.reduce((sum, current) => {
      // console.log(current)
    }, 0)

    roleChecked.numberButton = page.length
  }
  return roleChecked
}

export default {
  getObjectRoleForm,
  getDefaultRoleForm,
  parseRoleFromApi,
  isSuperAdmin,
  getRolePage
}