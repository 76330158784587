import { MAX_LENGTH_STRING } from '../constants'
import image from './image'
import notify from './notify'
import dataSend from './dataSend'
import dataReceiver from './dataReceiver'
import timer from './timer'
import setting from './setting'
import form from './form'
import role from './role'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

//valid param in function
export const isRequired = (name) => {
  throw new Error(`${name} is required`)
}

//truncate string
export const truncate = (str) => {
  if (!str) return ''
  return str.length > MAX_LENGTH_STRING ? str.substring(0, MAX_LENGTH_STRING - 3) + '...' : str
}

function numberWithCommas(x) {
  if (!x) return
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const parseField = (field) => {
  try {
    return JSON.parse(field)
  } catch (e) {
    return null
  }
}

const Utils = {
  ...image,
  ...notify,
  ...dataSend,
  ...dataReceiver,
  ...timer,
  ...setting,
  ...form,
  numberWithCommas,
  parseField
}

export const RoleUtils = {
  ...role
}

export default Utils

export const getTypeContentLibrary = (type) => {
  switch (type) {
    case 'image':
      return 6;
    case 'powerpoint':
      return 5;
    case 'pdf':
      return 4;
    case 'docx':
      return 3;
    case 'audio':
      return 2;
    case 'video':
      return 1;
  }

}

export const formatTimeSecond = (seconds) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedHrs = hrs.toString();
  const formattedMins = mins.toString();
  const formattedSecs = secs.toString();

  let str = '';

  if (hrs > 0) str += `${formattedHrs}h`;
  if (mins > 0) str += `${formattedMins}p`;
  if (secs > 0) str += `${formattedSecs}s`;

  return str ? str : '0s';
}

export const exportToCSV = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}