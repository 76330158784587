import {
  ACTION_TYPE,
  ACTION_TYPE_QUEST_HIDE,
  ACTION_TYPE_QUEST_OTHER,
  ACTION_TYPE_QUEST_PUBLIC,
  QUEST_CATE_KEY
} from '../constants/form'
import _ from 'lodash'

export const getTitle = (action) => {
  switch (action) {
    case ACTION_TYPE.CREATE:
      return 'Tạo'
    case ACTION_TYPE.EDIT:
      return 'Sửa'
    case ACTION_TYPE.DETAIL:
    case ACTION_TYPE.detail_question:
      return 'Chi tiết'
    case ACTION_TYPE.CHOOSE:
      return 'Chọn'
    case ACTION_TYPE.IMPORT:
      return 'Import'
    case ACTION_TYPE.EXPORT:
      return 'Export'
    case ACTION_TYPE.ASSIGN:
      return 'Gán'
    default:
      return ''
  }
}

/**
 * check and get field value in configuration
 * @param array
 * @returns {Array|*}
 */
export const getDataConfiguration = (array) => {
  if (!array) return []
  const dataClone = _.cloneDeep(array)
  if (typeof array === 'object' && array.length > 0) {
    if (dataClone[0].configuration_value) {
      try {
        let data = JSON.parse(dataClone[0].configuration_value)
        if (data.length > 0) {
          return data.map((item, index) => {
            item.id = index
            return item
          })
        }
      } catch (e) {
        return []
      }
    }
  }
  return []
}


export const getInfoDeveloper = (array) => {
  const initObj = {}
  if (!array) return initObj
  const dataClone = _.cloneDeep(array)
  if (typeof array === 'object' && array.length > 0) {
    if (dataClone[0].configuration_value) {
      try {
        return JSON.parse(dataClone[0].configuration_value)
      } catch (e) {
        return initObj
      }
    }
  }
  return initObj
}

/**
 * check & get policy data returned from api
 * @param array
 * @returns {string}
 */
export const getStringHtml = (array) => {
  if (!array) return ''
  if (typeof array === 'object' && array.length > 0) {
    if (array[0].configuration_value) {
      try {
        return array[0].configuration_value.toString()
      } catch (e) {
        return ''
      }
    }
  }
  return ''
}

/**
 *
 * @param array
 * @param item
 * @returns {boolean}
 */
export const checkIsExistsUIDefault = (array, item) => {
  if (!array) return
  for (let i of array) {
    if (i.level === item.level && i.gender === item.gender) return true
  }
  return false
}

export const getActionType = (category_key) => {
  let actionType = null
  switch (category_key) {
    case QUEST_CATE_KEY.hide:
      actionType = ACTION_TYPE_QUEST_HIDE
      break
    case QUEST_CATE_KEY.study:
      actionType = ACTION_TYPE_QUEST_PUBLIC
      break
    case QUEST_CATE_KEY.other:
      actionType = ACTION_TYPE_QUEST_OTHER
      break
    default:
      actionType = []
  }
  return actionType
}

export const isShowTargetValue = (actionType) => {
  const arrShow = [101, 102, 103, 104]
  return !!arrShow.includes(actionType)
}

export const isShowTargetQuantity = (categoryName) => {
  return categoryName === QUEST_CATE_KEY.study
}

export const isShowTimeInput = (actionType) => {
  const arrShow = [101, 103]
  return !!arrShow.includes(actionType)
}

export default {
  getTitle,
  getDataConfiguration,
  getStringHtml,
  checkIsExistsUIDefault,
  getActionType,
}