export default function getMenuData() {
  return [
    // {
    //   title: 'Dashboard',
    //   key: 'dashboard',
    //   url: '/dashboard',
    //   icon: 'fe fe-home',
    // roles: ['admin'], // set user roles with access to this route
    // },
    {
      title: 'Trường học',
      key: 'school',
      icon: 'fe fe-home',
      url: '/school',
    },
    {
      title: 'Người dùng',
      key: 'user',
      icon: 'fe fe-user',
      children: [
        {
          title: 'Admin',
          key: 'admin',
          url: '/admin',
        },
        {
          title: 'Giáo viên',
          key: 'teacher',
          url: '/teacher',
        },
        {
          title: 'Học sinh',
          key: 'pupil',
          url: '/pupil',
        },
        {
          title: 'Phụ huynh',
          key: 'parent',
          url: '/parent',
        },
      ],
    },

    {
      title: 'License Key',
      key: 'license_key_manager',
      icon: 'fa fa-key',
      children: [
        {
          title: 'Danh sách License Key',
          key: 'license_key',
          url: '/license_key',
        },
        {
          title: 'Lịch sử tạo License Key',
          key: 'license_key_block',
          url: '/license_key_block',
        }
      ],
    },

    {
      title: 'Thiết bị',
      key: 'device',
      icon: 'fe fe-tablet',
      children: [
        {
          title: 'Học sinh',
          key: 'device_pupil',
          url: '/device_pupil',
        },
        {
          title: 'Phụ huynh',
          key: 'device_parent',
          url: '/device_parent',
        },
      ],
    },
    {
      title: 'Danh mục',
      key: 'category',
      icon: 'fe fe-file-text',
      children: [
        {
          title: 'Lớp học',
          key: 'course',
          url: '/course',
        },
        {
          title: 'Nhiệm vụ',
          key: 'quest',
          url: '/quest',
        },
        // {
        //   title: 'Sự kiện todo',
        //   key: 'event',
        //   url: '/event',
        // },
        // {
        //   title: 'Học kỳ',
        //   key: 'semester',
        //   url: '/semester',
        // },
        {
          title: 'Chương trình',
          key: 'program',
          url: '/program',
        },
      ],
    },
    {
      title: 'Danh mục bài học',
      key: 'bai_hoc',
      icon: 'fe fe-book-open',
      children: [
        {
          title: 'Voice',
          key: 'voice',
          url: '/voice',
        },
        {
          title: 'Môn học',
          key: 'subject',
          url: '/subject',
        },
        {
          title: 'Topic',
          key: 'topic',
          url: '/topic',
        },
      ],
    },
    {
      title: 'Nội dung học tập',
      key: 'content_learning',
      icon: 'fa fa-pencil',
      children: [
        {
          title: 'Câu hỏi',
          key: 'question',
          url: '/question',
        },
        {
          title: 'Thư viện media',
          key: 'question_media',
          url: '/question_media',
        },
        {
          title: 'Biên soạn câu hỏi',
          key: 'question_compilation',
          url: '/question_compilation',
        },
        {
          title: 'Tập làm văn',
          key: 'taplamvan',
          url: '/taplamvan',
        },
      ],
    },
    {
      title: 'Sách',
      key: 'book_content',
      icon: 'fe fe-book',
      children: [
        {
          title: 'Danh sách sách',
          key: 'book',
          url: '/book',
        },
        {
          title: 'Chương học',
          key: 'chapter',
          url: '/chapter',
        },
        {
          title: 'Bài học',
          key: 'lesson',
          url: '/lesson',
        },
        {
          title: 'Lý thuyết',
          key: 'theory',
          url: '/theory',
        },
        {
          title: 'Video',
          key: 'video',
          url: '/video',
        },
      ],
    },
    {
      title: 'Mở rộng',
      key: 'extend',
      icon: 'fe fe-maximize-2',
      children: [
        {
          title: 'Video mở rộng',
          key: 'video_extend',
          url: '/video_extend',
        },
        {
          title: 'Ứng dụng mở rộng',
          key: 'app_extend',
          url: '/app_extend',
        }
      ],
    },
    {
      title: 'Quản lý thư viện',
      key: 'reference',
      url: '/reference',
      icon: 'fe fe-book',
    },
    {
      title: 'Chi tiết ứng dụng NextA',
      key: 'app_detail',
      url: '/app_next_a',
      icon: 'fe fe-grid',
    },
    {
      title: 'Vật phẩm',
      key: 'products',
      icon: 'fe fe-box',
      children: [
        {
          title: 'Vật phẩm Coin',
          key: 'coin',
          url: '/coin',
        },
        {
          title: 'Sách',
          key: 'book',
          url: '/book',
        },
      ],
    },
    {
      title: 'Cấu hình',
      key: 'configurations',
      icon: 'fe fe-settings',
      children: [
        {
          title: 'Điều khoản',
          key: 'policy',
          url: '/policy',
        },
        {
          title: 'FAQ',
          key: 'faq',
          url: '/FAQ',
        },
        {
          title: 'Hướng dẫn sử dụng',
          key: 'guide',
          url: '/user_guide',
        },
        {
          title: 'Tiêu chí thưởng coin',
          key: 'rule_grant_coin',
          url: '/rule_grant_coin',
        },
        {
          title: 'Thông tin nhà phát triển',
          key: 'information_dev',
          url: '/developer_information',
        },
        {
          title: 'Hình ảnh ứng dụng',
          key: 'image_ch_play',
          url: '/image-ch-play',
        },
      ],
    },
    {
      title: 'Khiếu nại',
      key: 'issue',
      icon: 'fe fe-alert-triangle',
      url: '/issue',
    },
    {
      title: 'Thử thách',
      key: 'challenge',
      icon: 'fe fe-users',
      url: '/challenge',
    },
    {
      title: 'Chat',
      key: 'chat',
      icon: 'fe fe-mail',
      url: '/chat',
    },
    {
      title: 'Lịch sử',
      key: 'history',
      icon: 'fe fe-list',
      url: '/history',
    },

    {
      title: 'Báo cáo',
      key: 'report',
      icon: 'fe fe-bar-chart',
      children: [
        {
          title: 'Học sinh',
          key: 'report_pupil',
          url: '/report_pupil',
        }
      ],
    },

    {
      title: 'Hệ thống',
      key: 'sysparam',
      icon: 'fe fe-sliders',
      children: [
        {
          title: 'Thông báo',
          key: 'notification',
          url: '/notification',
        },
        {
          title: 'Version',
          key: 'version',
          url: '/version',
        },
      ],
    },

    {
      title: 'Thư viện',
      key: 'thu_vien',
      icon: 'fe fe-librảy',
      children: [
        {
          title: 'Danh mục',
          key: 'danh_muc',
          url: '/library/danh_muc',
        },
        {
          title: 'Kế hoạch giảng dạy',
          key: 'ke_hoach_giang_day',
          url: '/library/ke_hoach_giang_day',
        },
        {
          title: 'Bài giảng điện tử',
          key: 'bai_giang_dien_tu',
          url: '/library/bai_giang_dien_tu',
        },
        {
          title: 'Học liệu',
          key: 'hoc_lieu',
          url: '/library/hoc_lieu',
        },
        {
          title: 'Đề thi tham khảo',
          key: 'de_thi_tham_khao',
          url: '/library/de_thi_tham_khao',
        },
        {
          title: 'Trò chơi',
          key: 'tro_choi',
          url: '/library/tro_choi',
        },
        {
          title: 'Chủ đề',
          key: 'chu_de',
          url: '/library/chu_de',
        },
        {
          title: 'Phòng thí nghiệm ảo',
          key: 'simulation',
          url: '/library/simulation',
        },
      ],
    },

    {
      title: 'Tiện ích',
      key: 'tien_ich',
      icon: 'fe fe-librảy',
      children: [
        {
          title: 'Website',
          key: 'website',
          url: '/util/website',
        },
        {
          title: 'Ứng dụng',
          key: 'ung_dung',
          url: '/util/ung_dung',
        },
      ],
    },
  ]
}
