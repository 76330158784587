import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Home from './pages/home'

const routes = [
  {
    path: '/',
    Component: lazy(() => import('pages/home')),
    exact: true,
  },
  {
    path: '/school',
    Component: lazy(() => import('pages/school')),
    exact: true,
  },

  {
    path: '/school/:id/classroom',
    Component: lazy(() => import('pages/school/detail/classroom')),
    exact: true,
  },
  {
    path: '/school/:id/classroom/:class_id/pupil',
    Component: lazy(() => import('pages/school/detail/pupil')),
    exact: true,
  },
  {
    path: '/school/:id/teacher',
    Component: lazy(() => import('pages/school/detail/teacher')),
    exact: true,
  },
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/change_password',
    Component: lazy(() => import('pages/auth/change_password')),
    exact: true,
  },
  {
    path: '/auth/forgot_password',
    Component: lazy(() => import('pages/auth/forgot_password')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  //User
  {
    path: '/admin',
    Component: lazy(() => import('pages/user/admin/index')),
    exact: true,
  },
  {
    path: '/parent',
    Component: lazy(() => import('pages/user/parent/index')),
    exact: true,
  },
  {
    path: '/pupil',
    Component: lazy(() => import('pages/user/pupil/index')),
    exact: true,
  },
  {
    path: '/teacher',
    Component: lazy(() => import('pages/user/teacher/index')),
    exact: true,
  },
  //Device
  {
    path: '/device_pupil',
    Component: lazy(() => import('pages/device/pupil/index')),
    exact: true,
  },
  {
    path: '/device_parent',
    Component: lazy(() => import('pages/device/parent/index')),
    exact: true,
  },
  // Category
  {
    path: '/course',
    Component: lazy(() => import('pages/category/course/index')),
    exact: true,
  },
  {
    path: '/quest',
    Component: lazy(() => import('pages/category/quest/index')),
    exact: true,
  },
  {
    path: '/event',
    Component: lazy(() => import('pages/category/event/index')),
    exact: true,
  },
  {
    path: '/subject',
    Component: lazy(() => import('pages/bai_hoc/subject/index')),
    exact: true,
  },
  // {
  //   path: '/semester',
  //   Component: lazy(() => import('pages/category/semester/index')),
  //   exact: true,
  // },
  // {
  //   path: '/event',
  //   Component: lazy(() => import('pages/category/event/index')),
  //   exact: true,
  // },
  {
    path: '/program',
    Component: lazy(() => import('pages/category/program/index')),
    exact: true,
  },
  {
    path: '/topic',
    Component: lazy(() => import('pages/bai_hoc/topic/index')),
    exact: true,
  },
  {
    path: '/voice',
    Component: lazy(() => import('pages/bai_hoc/voice/index')),
    exact: true,
  },
  {
    path: '/chapter',
    Component: lazy(() => import('pages/bai_hoc/chapter/index')),
    exact: true,
  },
  {
    path: '/lesson',
    Component: lazy(() => import('pages/bai_hoc/lesson/index')),
    exact: true,
  },
  {
    path: '/question',
    Component: lazy(() => import('pages/content_learning/question/index')),
    exact: true,
  },
  {
    path: '/question_compilation',
    Component: lazy(() => import('pages/content_learning/question_compilation/index')),
    exact: true,
  },
  {
    path: '/taplamvan',
    Component: lazy(() => import('pages/content_learning/taplamvan2/index')),
    exact: true,
  },
  {
    path: '/question_media',
    Component: lazy(() => import('pages/content_learning/question_media/index')),
    exact: true,
  },
  {
    path: '/theory',
    Component: lazy(() => import('pages/content_learning/theory/index')),
    exact: true,
  },
  {
    path: '/video',
    Component: lazy(() => import('pages/content_learning/video/index')),
    exact: true,
  },
  {
    path: '/video_extend',
    Component: lazy(() => import('pages/extend/video_extend/index')),
    exact: true,
  },
  {
    path: '/app_extend',
    Component: lazy(() => import('pages/extend/app_extend/index')),
    exact: true,
  },
  {
    path: '/reference',
    Component: lazy(() => import('pages/reference/index')),
    exact: true,
  },
  {
    path: '/app_next_a',
    Component: lazy(() => import('pages/detail_app_next_a/index')),
    exact: true,
  },
  // Product
  {
    path: '/book',
    Component: lazy(() => import('pages/product/book/index')),
    exact: true,
  },
  {
    path: '/book/editor/:idBook',
    Component: lazy(() => import('pages/product/book/book/EditorBook/index')),
    exact: true,
  },
  {
    path: '/question-editor',
    Component: lazy(() => import('pages/content_learning/question/create/index')),
    exact: true,
  },
  {
    path: '/coin',
    Component: lazy(() => import('pages/product/coin/index')),
    exact: true,
  },
  // Configurations
  {
    path: '/FAQ',
    Component: lazy(() => import('pages/configuration/faq/Grid')),
    exact: true,
  },
  {
    path: '/policy',
    Component: lazy(() => import('pages/configuration/policy/index')),
    exact: true,
  },
  {
    path: '/user_guide',
    Component: lazy(() => import('pages/configuration/user_guide/index')),
    exact: true,
  },
  {
    path: '/rule_grant_coin',
    Component: lazy(() => import('pages/configuration/rule_grant_coin/Grid')),
    exact: true,
  },
  {
    path: '/developer_information',
    Component: lazy(() => import('pages/configuration/developer_information')),
    exact: true,
  },
  {
    path: '/image-ch-play',
    Component: lazy(() => import('pages/configuration/image_ch_play/index')),
    exact: true,
  },
  // Issue
  {
    path: '/issue',
    Component: lazy(() => import('pages/issues/index')),
    exact: true,
  },
  //Param
  {
    path: '/history',
    Component: lazy(() => import('pages/history/index')),
    exact: true,
  },
  {
    path: '/notification',
    Component: lazy(() => import('pages/system_parameters/notification/index')),
    exact: true,
  },
  {
    path: '/version',
    Component: lazy(() => import('pages/system_parameters/version/index')),
    exact: true,
  },


  {
    path: '/library/bai_giang_dien_tu',
    Component: lazy(() => import('pages/library/bai_giang_dien_tu/index')),
    exact: true,
  },
  {
    path: '/library/ke_hoach_giang_day',
    Component: lazy(() => import('pages/library/ke_hoach_giang_day/index')),
    exact: true,
  },
  {
    path: '/library/de_thi_tham_khao',
    Component: lazy(() => import('pages/library/de_thi_tham_khao/index')),
    exact: true,
  },
  {
    path: '/library/tro_choi',
    Component: lazy(() => import('pages/library/tro_choi/index')),
    exact: true,
  },
  {
    path: '/library/chu_de',
    Component: lazy(() => import('pages/library/chu_de/index')),
    exact: true,
  },
  {
    path: '/library/danh_muc',
    Component: lazy(() => import('pages/library/danh_muc/index')),
    exact: true,
  },
  {
    path: '/library/hoc_lieu',
    Component: lazy(() => import('pages/library/hoc_lieu/index')),
    exact: true,
  },
  {
    path: '/library/simulation',
    Component: lazy(() => import('pages/library/simulation/index')),
    exact: true,
  },
  {
    path: '/util/website',
    Component: lazy(() => import('pages/util/website/index')),
    exact: true,
  },
  {
    path: '/util/ung_dung',
    Component: lazy(() => import('pages/util/application/index')),
    exact: true,
  },
  {
    path: '/challenge',
    Component: lazy(() => import('pages/challenge/index')),
    exact: true,
  },

  // báo cáo
  {
    path: '/report_pupil',
    Component: lazy(() => import('pages/report/pupil/index')),
    exact: true,
  },
  {
    path: '/report_pupil_mission/:pupil_id',
    Component: lazy(() => import('pages/report/pupil_mission/index')),
    exact: true,
  },
  {
    path: '/chat',
    Component: lazy(() => import('pages/chat/index')),
    exact: true,
  },

  // license key
  {
    path: '/license_key',
    Component: lazy(() => import('pages/license_key/index')),
    exact: true,
  },
  {
    path: '/license_key_block',
    Component: lazy(() => import('pages/license_key/license_key_block/index')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Home />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
