import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import classNames from 'classnames'
import store from 'store'
import _, { find } from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import style from './style.module.scss'
import { ACTION_TYPE } from '../../../../../constants/form'
import { RoleUtils } from '../../../../../utils'
import { actionTypes } from "../../../../../redux/actionTypes"
import axios from 'axios'
import io from 'socket.io-client'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.role,
  user: user,
})

const MenuLeft = (props) => {

  const {
    dispatch,
    menuData = [],
    location: { pathname },
    isMenuCollapsed,
    isMobileView,
    isMenuUnfixed,
    isMenuShadow,
    leftMenuWidth,
    menuColor,
    logo,
    role,
    user
  } = props
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])
  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])


  useEffect(() => {

    let socket = io(`${process.env.REACT_APP_SOCKET_URL ? process.env.REACT_APP_SOCKET_URL : 'ws://api.nexta.vn'}?id=${user.id}&from=admin`);
    socket.on(`nextaNotification/admin/${user.id}`,async (a) => {
      dispatch({
        type: actionTypes.SET_MESSAGE,
        payload: {
          message: a
        }
      })

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/apis/cms/chat/unseen`, {
          user_id: user.id,
          user_type: 1,
        });
        console.log("UPDATE UNSSEN", response.data.data)
        dispatch({
          type: actionTypes.UPDATE_NOTI_MESSAGE,
          payload: {
            num: response.data.data.total
          }
        })
      } catch (error) {
        console.log(error)
      }

    });

    (async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/apis/cms/chat/unseen`, {
          user_id: user.id,
          user_type: 1,
        });
        dispatch({
          type: actionTypes.UPDATE_NOTI_MESSAGE,
          payload: {
            num: response.data.data.total
          }
        })
      } catch (error) {
        console.log(error)
      }

    })()

    // dispatch({
    //   type: actionTypes.UPDATE_NOTI_MESSAGE,
    //   payload: {}
    // })

  }, [])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed
      }
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`}></span>}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      )
    }

    // example: items = [quest: {title: "", key: "course", url: ""}]
    // example: key = select
    const generateSubmenu = (items, key) =>
      items.map(menuItem => {
        //check role has view?
        if (!RoleUtils.isSuperAdmin(role) && typeof role === 'object') {
          const roleKey = role && role[key]
          const roleView = role && roleKey && roleKey[menuItem.key] ? roleKey[menuItem.key] : []
          if (!roleView.includes(ACTION_TYPE.VIEW)) {
            return null
          }
        }

        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      // if (menuItem.roles && !menuItem.roles.includes(role)) {
      //   return null
      // }

      //with item has children
      if (menuItem.children) {
        if (!RoleUtils.isSuperAdmin(role) && typeof role === 'object') {
          const isView = role && role[menuItem.key] ? role[menuItem.key] : []
          //check empty role with this menu
          if (_.isEmpty(isView)) {
            return null
          }
        }

        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children, menuItem.key)}
          </Menu.SubMenu>
        )
      } else {
        // with item hasn't children
        if (!RoleUtils.isSuperAdmin(role) && menuItem.url) {
          if (menuItem.key === "school") {
            if (!role.school) return null
          } else {
            //check role has view?
            const roleView = role && role[menuItem.key] ? role[menuItem.key] : []
            if (!roleView.includes(ACTION_TYPE.VIEW)) {
              return null
            }
          }
        }
      }
      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
      width: leftMenuWidth,
      collapsible: false,
      collapsed: false,
      onCollapse
    }
    : {
      width: leftMenuWidth,
      collapsible: true,
      collapsed: isMenuCollapsed,
      onCollapse,
      breakpoint: 'lg'
    }

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
        [style.borderRight]: true
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)'
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <img src="resources/images/logo.svg" className="mr-2" alt="NextA Admin" />
            <div className={style.name}>NextA Admin</div>
            {/* {logo === 'Clean UI Pro' && <div className={style.descr}>React</div>} */}
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
