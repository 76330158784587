import {actionTypes} from '../actionTypes'

const initialState = {
  menuData: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE_MENU:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
