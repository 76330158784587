import {all, call, put, takeEvery} from 'redux-saga/effects'
import {notification} from 'antd'
import {history} from 'index'
import * as auth from '../../services/actions/auth'
import {actionTypes} from '../actionTypes'
import store from 'store'
import {SUPER_ADMIN} from '../../constants'
import {RoleUtils} from '../../utils'
import { updateRole } from '../role/actions'
import {store as storeRedux} from '../../index'


export function* LOGIN({payload}) {
  const {email, password} = payload
  yield put({
    type: actionTypes.SET_STATE_USER,
    payload: {
      loading: true
    }
  })
  const success = yield call(auth.login, email, password)

  if (success) {
    yield put({
      type: actionTypes.LOAD_CURRENT_ACCOUNT
    })
    // yield
    history.push('/')
    notification.success({
      message: 'Đăng nhập thành công!'
    })
  }
  yield put({
    type: actionTypes.SET_STATE_USER,
    payload: {
      loading: false
    }
  })
}

export function* REGISTER({payload}) {
  const {email, password, name} = payload
  yield put({
    type: actionTypes.SET_STATE_USER,
    payload: {
      loading: true
    }
  })
  const success = yield call(auth.register, email, password, name)

  if (success) {
    yield put({
      type: actionTypes.LOAD_CURRENT_ACCOUNT
    })
    yield history.push('/')
    notification.success({
      message: 'Đăng ký thành công'
    })
  }
  if (!success) {
    yield put({
      type: actionTypes.SET_STATE_USER,
      payload: {
        loading: false
      }
    })
  }
}

export function* FORGOT_PASSWORD({payload}) {
  const {email} = payload
  yield put({
    type: actionTypes.SET_STATE_USER,
    payload: {
      loading: true
    }
  })
  const success = yield call(auth.forgotPassword, email)
  console.log('success',success);
  if (success) {
    history.push('/auth/login')
    notification.success({
      message: 'Reset mật khẩu thành công'
    })
    yield put({
      type: actionTypes.SET_STATE_USER,
      payload: {
        loading: false
      }
    })
  }
  if (!success) {
    yield put({
      payload: {
        loading: false
      }
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actionTypes.SET_STATE_USER,
    payload: {
      loading: true
    }
  })
  const accessToken = store.get('accessToken')
  if (accessToken) {
    const response = yield call(auth.currentAccount)
    if (response.data) {
      const {role, id, email, full_name, avatar} = response.data
      if(id == 73){
        store.set("role", "ctv_english")
      }
      const roleValue = role ? RoleUtils.isSuperAdmin(role?.role_value) ? SUPER_ADMIN : RoleUtils.parseRoleFromApi(role?.role_value) : null

      yield put({
        type: actionTypes.SET_STATE_USER,
        payload: {
          id,
          email,
          full_name,
          avatar,
          authorized: true,
          role: roleValue
        }
      })
      const roles = updateRole(roleValue)
      yield put({
        type: actionTypes.SET_STATE_ROLE,
        payload: roles
      })
    }
  }
  yield put({
    type: actionTypes.SET_STATE_USER,
    payload: {
      loading: false
    }
  })
}

export function* LOGOUT() {
  // yield call(auth.logout)
  store.remove('accessToken')
  yield put({
    type: actionTypes.SET_STATE_USER,
    payload: {
      id: '',
      email: '',
      full_name: '',
      authorized: false,
      loading: false
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.LOGIN, LOGIN),
    takeEvery(actionTypes.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actionTypes.REGISTER, REGISTER),
    takeEvery(actionTypes.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actionTypes.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT() // run once on app load to check user auth
  ])
}
