import { RoleUtils } from '../../utils'

export const updateRole = (roleValue) => {
  //List role a page

  //User
  const adminPage = roleValue?.user?.admin ?? null
  const rolePage = roleValue?.user?.role ?? null
  const parentPage = roleValue?.user?.parent ?? null
  const pupilPage = roleValue?.user?.pupil ?? null
  const teacherPage = roleValue?.user?.teacher ?? null

  //Device
  const deviceParentPage = roleValue?.device?.device_parent ?? null
  const devicePupilPage = roleValue?.device?.device_pupil ?? null

  //Danh muc
  const coursePage = roleValue?.category?.course ?? null
  const eventPage = roleValue?.category?.event ?? null
  const programPage = roleValue?.category?.program ?? null
  const questPage = roleValue?.category?.quest ?? null
  const semesterPage = roleValue?.category?.semester ?? null

  //Danh muc bai hoc
  const topicPage = roleValue?.bai_hoc?.topic ?? null
  const voicePage = roleValue?.bai_hoc?.voice ?? null
  const lessonPage = roleValue?.bai_hoc?.lesson ?? null
  const chapterPage = roleValue?.bai_hoc?.chapter ?? null
  const subjectPage = roleValue?.bai_hoc?.subject ?? null

  // Noi dung hoc tap
  const questionPage = roleValue?.content_learning?.question ?? null
  const questionImgPage = roleValue?.content_learning?.question_media ?? null
  const questionIMG = roleValue?.content_learning?.question_media_image ?? null
  const questionVoice = roleValue?.content_learning?.question_media_voice ?? null
  const questionVideo = roleValue?.content_learning?.question_media_video ?? null
  const theoryPage = roleValue?.content_learning?.theory ?? null
  const videoPage = roleValue?.content_learning?.video ?? null
  const questionCompilation = roleValue?.content_learning?.question_compilation ?? null
  const taplamvanPage = roleValue?.content_learning?.taplamvan ?? null
  const taplamvanDangbai = roleValue?.content_learning?.taplamvan_dangbai ?? null
  const taplamvanDebai = roleValue?.content_learning?.taplamvan_debai ?? null
  const taplamvanKieubai = roleValue?.content_learning?.taplamvan_kieubai ?? null
  const taplamvanHuongdan = roleValue?.content_learning?.taplamvan_huongdan ?? null
  //Mo rong
  const appExtendPage = roleValue?.extend?.app_extend ?? null
  const videoExtendPage = roleValue?.extend?.video_extend ?? null

  //App nextA
  const appNextAPage = roleValue?.app_next_a ?? null

  //Vat pham
  const coinPage = roleValue?.products?.coin ?? null
  const bookPage = roleValue?.products?.book ?? null
  const bookChapter = roleValue?.products?.book_chapter ?? null
  const bookLesson = roleValue?.products?.book_lesson ?? null
  const bookTheory = roleValue?.products?.book_theory ?? null
  const bookVideo = roleValue?.products?.book_video ?? null
  const bookTheoryItem = roleValue?.products?.book_theory_item ?? null
  const bookVideoItem = roleValue?.products?.book_video_item ?? null
  const bookVideoExercise = roleValue?.products?.coin ?? null
  //Config
  const policyPage = roleValue?.configurations?.policy ?? null
  const faqPage = roleValue?.configurations?.faq ?? null
  const userGuidePage = roleValue?.configurations?.user_guide ?? null
  const ruleGrantCoinPage = roleValue?.configurations?.rule_grant_coin ?? null
  const developerInformationPage = roleValue?.configurations?.developer_information ?? null
  const issuePage = roleValue?.issue ?? null
  const imageChPlay = roleValue?.configurations?.image_ch_play ?? null;
  //Param
  const versionPage = roleValue?.sysparam?.version ?? null
  const appDetail = roleValue?.app_detail ?? null;

  return {
    // user
    admin: RoleUtils.getRolePage(adminPage, roleValue),
    role: RoleUtils.getRolePage(rolePage, roleValue),
    teacher: RoleUtils.getRolePage(teacherPage, roleValue),
    parent: RoleUtils.getRolePage(parentPage, roleValue),
    pupil: RoleUtils.getRolePage(pupilPage, roleValue),
    // device
    device_parent: RoleUtils.getRolePage(deviceParentPage, roleValue),
    device_pupil: RoleUtils.getRolePage(devicePupilPage, roleValue),
    //danh muc
    course: RoleUtils.getRolePage(coursePage, roleValue),
    quest: RoleUtils.getRolePage(questPage, roleValue),
    event: RoleUtils.getRolePage(eventPage, roleValue),
    semester: RoleUtils.getRolePage(semesterPage, roleValue),
    program: RoleUtils.getRolePage(programPage, roleValue),
    //danh muc bai hoc
    voice: RoleUtils.getRolePage(voicePage, roleValue),
    subject: RoleUtils.getRolePage(subjectPage, roleValue),
    topic: RoleUtils.getRolePage(topicPage, roleValue),
    chapter: RoleUtils.getRolePage(chapterPage, roleValue),
    lesson: RoleUtils.getRolePage(lessonPage, roleValue),
    //noi dung hoc tap
    theory: RoleUtils.getRolePage(theoryPage, roleValue),
    video: RoleUtils.getRolePage(videoPage, roleValue),
    question: RoleUtils.getRolePage(questionPage, roleValue),
    question_media: RoleUtils.getRolePage(questionImgPage, roleValue),
    // question_media_image: RoleUtils.getRolePage(questionIMG, roleValue),
    // question_media_voice: RoleUtils.getRolePage(questionVoice, roleValue),
    // question_media_video: RoleUtils.getRolePage(questionVideo, roleValue),
    question_compilation: RoleUtils.getRolePage(questionCompilation, roleValue),
    taplamvan: RoleUtils.getRolePage(taplamvanPage, roleValue),
    // taplamvan_dangbai: RoleUtils.getRolePage(taplamvanDangbai, roleValue),
    // taplamvan_debai: RoleUtils.getRolePage(taplamvanDebai, roleValue),
    // taplamvan_kieubai: RoleUtils.getRolePage(taplamvanKieubai, roleValue),
    // taplamvan_huongdan: RoleUtils.getRolePage(taplamvanHuongdan, roleValue),
    //extend
    video_extend: RoleUtils.getRolePage(videoExtendPage, roleValue),
    app_extend: RoleUtils.getRolePage(appExtendPage, roleValue),
    //app nexta
    app_next_a: RoleUtils.getRolePage(appNextAPage, roleValue),
    //vat pham
    coin: RoleUtils.getRolePage(coinPage, roleValue),
    book: RoleUtils.getRolePage(bookPage, roleValue),
    // book_chapter: RoleUtils.getRolePage(bookChapter, roleValue),
    // book_lesson: RoleUtils.getRolePage(bookLesson, roleValue),
    // book_theory: RoleUtils.getRolePage(bookTheory, roleValue),
    // book_video: RoleUtils.getRolePage(bookVideo, roleValue),
    // book_theory_item: RoleUtils.getRolePage(bookTheoryItem, roleValue),
    // book_video_item: RoleUtils.getRolePage(bookVideoItem, roleValue),
    // book_video_exercise: RoleUtils.getRolePage(bookVideoExercise, roleValue),
    //config
    policy: RoleUtils.getRolePage(policyPage, roleValue),
    faq: RoleUtils.getRolePage(faqPage, roleValue),
    user_guide: RoleUtils.getRolePage(userGuidePage, roleValue),
    rule_grant_coin: RoleUtils.getRolePage(ruleGrantCoinPage, roleValue),
    developer_information: RoleUtils.getRolePage(developerInformationPage, roleValue),
    app_detail: RoleUtils.getRolePage(appDetail, roleValue),
    imageChPlay: RoleUtils.getRolePage(imageChPlay, roleValue),
    issue: RoleUtils.getRolePage(issuePage, roleValue),
    //param
    sysparam: RoleUtils.getRolePage(versionPage, roleValue),
  }
}