import apiClient from 'services/axios'
import store from 'store'
import {actionTypes} from '../../redux/actionTypes'
import {notification} from 'antd'
import {startFetching, stopFetching} from './common'

/**
 * login
 * @param email
 * @param password
 * @returns {Promise<void|*|undefined>}
 */
export async function login(email, password) {
  return apiClient
    .post('/apis/cms/login', {
      email,
      password
    })
    .then(response => {
      if (response && response.status === 200) {
        const token = response.data.data
        if (token) {
          store.set('accessToken', token)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export function changePassword(data) {
  return async (dispatch) => {
    dispatch(startFetching(actionTypes.SET_STATE_USER))
    try {
      const res = await apiClient.put('/apis/cms/user_admin/changepassword', data)
      if (res && res.data) {
        notification.success({
          message: 'Đổi mật khẩu thành công!',
        })
        store.remove('accessToken')
        dispatch({
          type: actionTypes.LOGOUT
        })
      }
    }catch (e) {

    }
    dispatch(stopFetching(actionTypes.SET_STATE_USER))
  }
}

export async function forgotPassword(email) {
  console.log('apiClient', apiClient);
  return apiClient
    .post('/apis/cms/forgotPassword', {
      email
    })
    .then(response => {
      if (response && response.data) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
    
}


/**
 * register account admin
 * @param email
 * @param password
 * @param name
 * @returns {Promise<void|*|undefined>}
 */
export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name
    })
    .then(response => {
      if (response) {
        const {accessToken} = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

/**
 * get current account
 * @returns {Promise<void|*|undefined>}
 */
export async function currentAccount() {
  return apiClient
    .get('/apis/cms/user_admin/verify')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

/**
 * logout
 * @returns {Promise<void|*|undefined>}
 */
export function logout() {
  return async dispatch => {
    store.remove('accessToken')
    dispatch({
      type: actionTypes.LOGOUT
    })
  }
}
