import { actionTypes } from '../actionTypes'

const initialState = {
    message: null
}

export default function chatReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_MESSAGE:
            return { ...state, ...action.payload }
        default:
            return state
    }
}
