
export const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

//check path is img
export const checkIsImage = (path) => {
  if (!path) return false
  const regex = new RegExp('data:image/([a-zA-Z]*);base64')
  return !!(path || regex.test(path))
}

export const checkIsBase64 = (path) => {
  if (!path) return false
  const regex = new RegExp('data:image/([a-zA-Z]*);base64')
  return !!(regex.test(path))
}

const getLinkImage = (path) => {
  const regex = new RegExp(/^(http|https):\/\//g);
  return !!(regex.test(path)) ? path : path && `${process.env.REACT_APP_BASE_IMG_VNG}${path}`
}

const getLinkImageAWS = (path) => {
  return path && `${process.env.REACT_APP_BASE_IMG_VNG}${path}`
}

export default {
  getBase64,
  checkIsImage,
  checkIsBase64,
  getLinkImage,
  getLinkImageAWS
}