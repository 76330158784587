import {
  GET_BOOK_SUCCESS,
  GET_LIST_CHAPTER_SUCCESS,
  GET_LIST_LESSON_SUCCESS,
  GET_LIST_THEORY_SECTION_SUCCESS,
  GET_LIST_THEORY_SUCCESS, GET_LIST_VIDEO_SECTION_SUCCESS,
  GET_LIST_VIDEO_SUCCESS,
  SET_CHAPTER,
  SET_LESSON,
  SET_THEORY, SET_THEORY_SECTION,
  SET_VIDEO, SET_VIDEO_SECTION,
  START_FETCHING_EDITOR_BOOK,
  STOP_FETCHING_EDITOR_BOOK
} from './action_type'

const initialState = {
  loading: false,
  data: null,
  book: null,

  chapter: null,
  chapters: null,

  lesson: null,
  lessons: null,

  lesson_theory: null,
  lesson_video: null,

  theory: null,
  theory_section: null,
  theory_sections: null,

  video: null,
  video_section: null,
  video_sections: null,

  isCreateSuccess: false,
  isUpdateSuccess: false,
  isRemoveSuccess: false
}

export default function bookReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_EDITOR_BOOK:
      return { ...state, loading: true }
    case STOP_FETCHING_EDITOR_BOOK:
      return { ...state, loading: false }

    case GET_BOOK_SUCCESS:
      return { ...state, book: action.payload }

    case SET_CHAPTER:
      return { ...state, chapter: action.payload }
    case GET_LIST_CHAPTER_SUCCESS:
      return { ...state, chapters: action.payload }

    case SET_LESSON:
      return { ...state, lesson: action.payload }
    case GET_LIST_LESSON_SUCCESS:
      return { ...state, lessons: action.payload }

    case SET_THEORY:
      return { ...state, theory: action.payload }
    case GET_LIST_THEORY_SUCCESS:
      return { ...state, lesson_theory: action.payload }
    case SET_THEORY_SECTION:
      return { ...state, theory_section: action.payload }
    case GET_LIST_THEORY_SECTION_SUCCESS:
      return { ...state, theory_sections: action.payload }

    case SET_VIDEO:
      return { ...state, video: action.payload }
    case SET_VIDEO_SECTION:
      return { ...state, video_section: action.payload }
    case GET_LIST_VIDEO_SUCCESS:
      return { ...state, lesson_video: action.payload }
    case GET_LIST_VIDEO_SECTION_SUCCESS:
      return { ...state, video_sections: action.payload }


    default:
      return state
  }
}
