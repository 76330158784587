export const START_FETCHING_TLV = 'START_FETCHING_TLV'
export const STOP_FETCHING_TLV = 'STOP_FETCHING_TLV'

export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS'
export const GET_LESSON_TYPE_SUCCESS = 'GET_LESSON_TYPE_SUCCESS'
export const GET_LESSON_FORMAT_SUCCESS = 'GET_LESSON_FORMAT_SUCCESS'
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS'
export const GET_GUIDE_SUCCESS = 'GET_GUIDE_SUCCESS'

export const SET_SUBJECT = 'SET_SUBJECT'
export const SET_LESSON_TYPE = 'SET_LESSON_TYPE'
export const SET_LESSON_FORMAT = 'SET_LESSON_FORMAT'
export const SET_QUESTION = 'SET_QUESTION'
export const SET_GUIDE = 'SET_GUIDE'

export const SET_QUESTION_FORMAT = 'SET_QUESTION_FORMAT'
export const GET_LIST_VIDEO_SUCCESS = 'GET_LIST_VIDEO_SUCCESS'