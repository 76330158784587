import { START_FETCHING, STOP_FETCHING } from './actionTypes'

const initialState = {
  loading: false,
}

export default function fetchingReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING:
      return { ...state, loading: true }
    case STOP_FETCHING:
      return { ...state, loading: false }
    default:
      return state
  }
}
