export const actionTypes = {
  //USER
  SET_STATE_USER: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',

  //SETTING
  SET_STATE_SETTING: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
  SET_PRIMARY_COLOR: 'settings/SET_PRIMARY_COLOR',
  SET_THEME: 'settings/SET_THEME',

  //MENU
  SET_STATE_MENU: 'menu/SET_STATE',
  GET_DATA_MENU: 'menu/GET_DATA',

  //ROLE
  SET_STATE_ROLE: 'SET_STATE_ROLE',

  UPDATE_NOTI_MESSAGE: 'UPDATE_NOTI_MESSAGE',
  SET_MESSAGE: 'SET_MESSAGE',
}