import moment from 'moment'
import { FORMAT_YMD } from '../constants'

const secondsToHms = (d) => {
  const dataErr = {
    text_show: null,
    time: null,
    timeShow: null
  }
  if (!d) return dataErr
  try {
    d = Number(d)
    let h = Math.floor(d / 3600)
    let m = Math.floor(d % 3600 / 60)
    let s = Math.floor(d % 3600 % 60)

    let hDisplay = h > 0 ? h + ' giờ ' : ''
    let mDisplay = m > 0 ? m + ' phút ' : ''
    let sDisplay = s > 0 ? s + ' giây' : ''
    const timeDetail = hDisplay + mDisplay + sDisplay

    const hPrefix = (h >= 0 && h < 10) ? `0${h}` : h
    const mPrefix = (m >= 0 && m < 10) ? `0${m}` : m
    const sPrefix = (s >= 0 && s < 10) ? `0${s}` : s

    const timeShow = `${hPrefix}:${mPrefix}:${sPrefix}`

    const time = {
      h,
      m,
      s
    }
    return {
      text_show: timeDetail,
      time,
      timeShow
    }
  } catch (e) {
    return dataErr
  }
}

const formatDate = (date, format) => {
  return moment(date).format(format ?? FORMAT_YMD)
}

function convertPeriodicTime(a){
  const t = a.split(" ");
  return `${t[2]} : ${t[1]} : ${t[0]}`
}


function getDateTime(timer){
  const date = new Date(timer * 1000);
  return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
}


const UtilDateTime = {
  convertPeriodicTime,
  secondsToHms,
  getDateTime,
  formatDate
}

export default UtilDateTime
