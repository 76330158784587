import { notification } from 'antd'

export const notifySuccess = (message) => {
	return notification.success({
		message: message
	})
}

export const notifyWarning = (message) => {
	return notification.warning({
		message: message
	})
}

export default {
	notifySuccess,
	notifyWarning
}