import {
  SET_QUESTION_SELECTED
} from './action_type'

const initialState = {
  loading: false,
  current_question: null
}

export default function questionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_QUESTION_SELECTED:
      return { ...state, current_question: action.payload }

    default:
      return state
  }
}
