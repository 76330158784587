import {
  SET_TAB,
  SET_CURRENT_VIDEO_CATEGORY,
  SET_CURRENT_BOOK_CATEGORY
} from './action_type'

const initialState = {
  tab: 'video_reference',
  currentVideoCate: null,
  currentBookCate: null,
}

export default function libManagerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TAB:
      return {...state, tab: action.payload}
    case SET_CURRENT_BOOK_CATEGORY:
      return {...state, currentBookCate: action.payload}
    case SET_CURRENT_VIDEO_CATEGORY:
      return {...state, currentVideoCate: action.payload}

    default:
      return state
  }
}
