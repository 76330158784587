import {actionTypes} from '../actionTypes'

const initialState = {
  admin: null,
  role: null,
  teacher: null,
  parent: null,
  pupil: null,
  device_parent: null,
  device_pupil: null,
  course: null,
  quest: null,
  event: null,
  semester: null,
  program: null,
  voice: null,
  subject: null,
  topic: null,
  chapter: null,
  lesson: null,
  theory: null,
  video: null,
  question: null,
  question_media: null,
  video_extend: null,
  app_extend: null,
  app_next_a: null,
  coin: null,
  book: null,
  policy: null,
  faq: null,
  user_guide: null,
  rule_grant_coin: null,
  developer_information: null,
  issue: null,
  sysparam: null,
}

export default function roleReducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.SET_STATE_ROLE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
